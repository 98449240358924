import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { MarketLocationIcon, SolarPpaIcon } from '@trawa-energy/ui-kit';
import { GridLine } from '../GridLine.tsx';
import { Box } from './Box.tsx';
import { PowerChip } from './PowerChip.tsx';
import {
    calcAnimationSpeedPercentage,
    directionToColorMap,
    directionToGradientMap,
    getGridBreak,
    getOffsetByNumOfNodes,
    useFormatKwh,
} from './utils.ts';
import { PowerPill } from './PowerPill.tsx';
import { MarketLocation as MarketLocationT, Direction } from './types.ts';

export type MarketLocationProps = {
    id: string;
    startElementId: string;
    marketLocation: MarketLocationT;
    numberOfMarketLocations: number;
    index: number;
    direction: Direction;
    maxMarketLocationPeakKwh: number;
    isTvScreen?: boolean;
};

export const MarketLocation: FC<MarketLocationProps> = ({
    id,
    startElementId,
    marketLocation,
    numberOfMarketLocations,
    index,
    direction,
    maxMarketLocationPeakKwh,
}) => {
    const formatKwh = useFormatKwh();
    const intl = useIntl();

    const boxStyle = 'w-32 2xl:w-52 gap-2 justify-center items-center p-2 2xl:p-4';

    const consumptionBoxStyle = 'flex gap-2 space-between items-center';

    const marketLocationLabelStyle = 'text-xs 2xl:text-base';

    return (
        <div key={marketLocation.id} className="h-full">
            <div className="flex items-center flex-col h-full">
                <PowerPill value={marketLocation.kwh} direction={marketLocation.direction} className="mb-4" />
                <Box id={id} className={boxStyle}>
                    <div className="size-full">
                        <div className={consumptionBoxStyle}>
                            <span className="shrink-0">
                                <MarketLocationIcon className="w-4 2xl:w-6" />
                            </span>

                            <div className={marketLocationLabelStyle}>
                                {marketLocation.type === 'SLP'
                                    ? intl.formatMessage(
                                          { id: 'overview.slpLabel' },
                                          { numOfSlps: parseInt(marketLocation.label) },
                                      )
                                    : marketLocation.label}

                                {marketLocation.type !== 'SLP' && marketLocation.peakKwh && (
                                    <p className="text-xs xs:text-base">
                                        {intl.formatMessage({ id: 'overview.peakKwhLabel' })}{' '}
                                        {formatKwh(marketLocation.peakKwh)}
                                    </p>
                                )}
                            </div>
                        </div>

                        {marketLocation.hasPv && (
                            <div className="flex items-center pt-2 gap-2">
                                <div>
                                    <span className="shrink-0">
                                        <SolarPpaIcon className="w-4 2xl:w-6" />
                                    </span>
                                </div>

                                <div>
                                    <p className="text-xs pb-1 2xl:text-base">PV-Erzeugung</p>
                                    <PowerChip color={directionToGradientMap['sending']} size="sm">
                                        {formatKwh(marketLocation.pvDatakwh ? parseInt(marketLocation.pvDatakwh) : 0)}
                                    </PowerChip>
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </div>
            <GridLine
                start={startElementId}
                end={id}
                startAnchor={{
                    position: 'bottom',
                    offset: {
                        x: getOffsetByNumOfNodes(numberOfMarketLocations)[index],
                        y: 0,
                    },
                }}
                endAnchor="top"
                animationSpeedPercentage={calcAnimationSpeedPercentage(marketLocation.kwh, maxMarketLocationPeakKwh)}
                dashColor={directionToColorMap[marketLocation.direction]}
                animationIsInverted={direction === 'sending'}
                gridBreak={getGridBreak(numberOfMarketLocations, index, 'bottomUp')}
            />
        </div>
    );
};
