import { z } from 'zod';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useAtom } from 'jotai/react';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';

// exported to be used in tests
const userSettingsStorageKey = 'UserSettings';

export enum AccountType {
    RealEstate = 'RealEstate',
    Default = 'Default',
}

export enum ACCOUNT {
    ACTIVE_TV = 'f357668c-8704-11ee-b9d1-0242ac120002',
}

export type AccountTypeT = (typeof AccountType)[keyof typeof AccountType];
export type UserSettings = z.infer<typeof userSettingsSchema>;

type UserSettingsReducer = (reducer: (userSettings: UserSettings) => UserSettings) => void;
type UserSettingsBasicSetter = (userSettings: UserSettings) => void;
type UserSettingsSetter = UserSettingsReducer & UserSettingsBasicSetter;

export type PartialUserSettings = z.infer<typeof partialUserSettingsSchema>;
const storage = createJSONStorage<PartialUserSettings>(() => sessionStorage);

// we only use these in special views that need to access partial user settings
export const partialUserSettingsAtom = atomWithStorage<PartialUserSettings>(
    userSettingsStorageKey,
    undefined!,
    storage,
);

export const useContractPartnerId = () => {
    const { userSettings } = useUserSettings();
    return (userSettings.accountType === AccountType.RealEstate && userSettings.contractPartnerId) || undefined;
};

// TODO: usage in TV screen needs to be rethought. only used to support tv screen that doesnt load user settings
export const useMaybeContractPartnerId = () => {
    const [userSettings] = useAtom(partialUserSettingsAtom);
    return (
        (userSettings && userSettings.accountType === AccountType.RealEstate && userSettings.contractPartnerId) ||
        undefined
    );
};

export const useUserSettings = () => {
    const [userSettings, setUserSettings] = useAtom(partialUserSettingsAtom);

    useEffect(() => {
        if (userSettings.accountType === AccountType.RealEstate && userSettings.contractPartnerId) {
            Sentry.setContext('Account', { contractPartnerId: userSettings.contractPartnerId });
        }
    }, [userSettings]);

    if (userSettings === undefined) {
        throw new Error('User settings can only be used in authenticated views. Are we authenticated?');
    }

    if (userSettings.accountType === AccountType.RealEstate && !userSettings.contractPartnerId) {
        throw new Error('User settings are not complete. Contract partner ID is not yet chosen.');
    }

    return {
        userSettings: userSettings as UserSettings,
        setUserSettings: setUserSettings as UserSettingsSetter,
    };
};

const baseUserSettingsSchema = z.object({
    accountId: z.string(),
});

const userSettingsSchema = baseUserSettingsSchema.and(
    z.union([
        z.object({
            accountType: z.literal(AccountType.RealEstate),
            contractPartnerId: z.string(),
        }),
        z.object({
            accountType: z.literal(AccountType.Default),
        }),
    ]),
);

const partialUserSettingsSchema = baseUserSettingsSchema.and(
    z.union([
        z.object({
            accountType: z.literal(AccountType.RealEstate),
            contractPartnerId: z.string().optional(),
        }),
        z.object({
            accountType: z.literal(AccountType.Default),
        }),
    ]),
);

type Account = NonNullable<inferRouterOutputs<AppRouter>['account']['getCurrent']>;

export function createPartialUserSettings({ account }: { account: Account }): PartialUserSettings {
    const baseValue = {
        accountId: account.id,
        contractPartnerId: undefined,
    };

    if (account.type === AccountType.RealEstate) {
        return {
            ...baseValue,
            accountType: AccountType.RealEstate,
        };
    } else {
        return {
            ...baseValue,
            accountType: AccountType.Default,
        };
    }
}
