import React, { FC, useState } from 'react';
import { Temporal } from 'temporal-polyfill';
import { useIntl } from 'react-intl';
import { AddressIcon, LoadingSpinner, SetexIcon } from '@trawa-energy/ui-kit';
import { Box } from '../consumption-grid/Box.tsx';
import { PowerSupplier } from '../consumption-grid/PowerSupplier.tsx';
import { GridContainer } from '../consumption-grid/GridContainer.tsx';
import { MarketLocation } from '../consumption-grid/MarketLocation.tsx';
import { calcAnimationSpeedPercentage, getGridBreak, getOffsetByNumOfNodes } from '../consumption-grid/utils.ts';
import { MarketLocation as MarketLocationT, PowerSupplier as PowerSupplierT } from '../consumption-grid/types.ts';
import { GridLine } from '../GridLine.tsx';
import { TimestampSlider } from './TimeSlider.tsx';
import { useTVGridData } from '../../hooks/useTVGridData.ts';

export const TvConsumptionGrid: FC<{ latestDayWithData: Temporal.PlainDate }> = ({ latestDayWithData }) => {
    const intl = useIntl();

    const [localTimestamp, setLocalTimestamp] = useState<Temporal.PlainDateTime>(
        Temporal.PlainDateTime.from(latestDayWithData).add({ hours: 8 }),
    );

    const { data, isLoading, isError } = useTVGridData({
        selectedDate: latestDayWithData,
        localTimestamp: localTimestamp,
    });

    const paddingTailwindClasses = 'pt-16 lg:pt-20 pb-3 mx-12';

    if (isLoading || data === undefined) {
        return (
            <GridContainer className={paddingTailwindClasses} isTvScreen>
                <LoadingSpinner />
            </GridContainer>
        );
    }

    if (isError) {
        return (
            <GridContainer className={`${paddingTailwindClasses} text-center`} isTvScreen>
                {intl.formatMessage({ id: 'overview.errorMsg' })}
            </GridContainer>
        );
    }

    return (
        <GridContainer isTvScreen>
            <div className={`flex flex-col items-center ${paddingTailwindClasses}`}>
                <div className="w-full flex justify-around gap-2 sm:gap-6 lg:w-1/2">
                    {data.powerSuppliers
                        // TODO replace with .toSorted() when supported
                        .sort((x, y) => {
                            const order: PowerSupplierT['type'][] = ['wind', 'solar', 'spot', 'futures'];

                            return order.indexOf(x.type) - order.indexOf(y.type);
                        })
                        .map((powerSupplier, index) => {
                            return (
                                <PowerSupplier
                                    key={powerSupplier.id}
                                    id={powerSupplier.id}
                                    kwh={powerSupplier.kwh}
                                    direction={powerSupplier.direction}
                                    type={powerSupplier.type}
                                    endElementId={data.id}
                                    numberOfPpas={data.powerSuppliers.length}
                                    index={index}
                                    maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                                    isTvScreen
                                />
                            );
                        })}
                </div>
                <div className="flex justify-center my-16 2xl:my-20">
                    <Box id={data.id} className="px-8 pt-6 pb-2">
                        <SetexIcon />
                    </Box>
                </div>
                <div className="flex justify-center gap-6 2xl:gap-12 w-full">
                    {data.addresses.map((address, index) => (
                        <div key={index}>
                            <div className="flex flex-col items-center mb-20 lg:mb-24">
                                <Box id={address.id} className="w-28 flex-col items-center px-4 py-2">
                                    <AddressIcon className="w-10 text-primary-blue-3" />
                                    <span className="font-bold text-xl">{address.name}</span>
                                </Box>
                            </div>
                            <div className={`grid gap-6 2xl:gap-12 grid-cols-${address.marketLocations.length}`}>
                                {address.marketLocations
                                    // TODO replace with .toSorted() when supported
                                    .sort((x, y) => {
                                        const order: MarketLocationT['type'][] = ['RLM', 'PV', 'SLP'];

                                        return order.indexOf(x.type) - order.indexOf(y.type);
                                    })
                                    .map((marketLocation, index) => {
                                        return (
                                            <MarketLocation
                                                id={marketLocation.id}
                                                key={marketLocation.id}
                                                startElementId={address.id}
                                                marketLocation={marketLocation}
                                                index={index}
                                                direction={marketLocation.direction}
                                                numberOfMarketLocations={address.marketLocations.length}
                                                maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                                                isTvScreen
                                            />
                                        );
                                    })}
                            </div>
                            <div>
                                <GridLine
                                    start={data.id}
                                    end={address.id}
                                    startAnchor={{
                                        position: 'bottom',
                                        offset: {
                                            x: getOffsetByNumOfNodes(data.addresses.length)[index],
                                            y: 0,
                                        },
                                    }}
                                    endAnchor="top"
                                    animationSpeedPercentage={calcAnimationSpeedPercentage(
                                        address.kwh,
                                        data.maxMarketLocationPeakKwh,
                                    )}
                                    dashColor="hsl(var(--primary-blue-4))"
                                    gridBreak={getGridBreak(data.addresses.length, index, 'bottomUp')}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {latestDayWithData && (
                <TimestampSlider
                    latestDayWithData={latestDayWithData}
                    value={localTimestamp}
                    onChange={setLocalTimestamp}
                />
            )}
        </GridContainer>
    );
};
