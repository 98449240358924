import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { inferRouterOutputs } from '@trpc/server';
import { useMemo } from 'react';
import { Temporal } from 'temporal-polyfill';
import { useMaybeContractPartnerId } from '../userSettings.ts';
import { trpc } from '../utils/trpc.ts';
import { CompanyConsumption, Direction, PowerSupplier } from '../components/consumption-grid/types.ts';
import { getPlainDateTimeFromString } from '../utils/getPlainDateTimeFromString.ts';
import { TimeZones } from '../locales/utils.ts';

type MarketLocation = inferRouterOutputs<AppRouter>['dashboard']['getMarketLocationsWithVolumeValues'][number];
type Account = NonNullable<inferRouterOutputs<AppRouter>['account']['getCurrent']>;
type Product = inferRouterOutputs<AppRouter>['dashboard']['getContractPartnerSupplyProducts'][number];
type SupplyItem = inferRouterOutputs<AppRouter>['consumptionGrid']['getSupply'][number];

type VolumeEntry = {
    timestampStart: string;
    volumeCurveId: string;
    kwh: number | undefined;
};

type VolumeEntryWithTemporal = VolumeEntry & {
    temporal: Temporal.ZonedDateTime;
};

type PVDVolumeEntry = {
    timestamp_start: string;
    timestamp_end: string;
    kwh: string;
};

export type MarketLocationWithVolumeEntries = MarketLocation & {
    volumeEntries: VolumeEntryWithTemporal[];
    volumeEntriesByMarketLocation?: VolumeEntry[];
    pvData?: PVDVolumeEntry[];
    peakKwh: number;
    marketLocationPeak?: number;
    pvDatakwh?: string;
};

// TODO should come from DB/TRCP types
export type ProductType =
    | 'trawa_fee'
    | 'goo'
    | 'balancing_product'
    | 'Solar PPA'
    | 'Peakload Future'
    | 'Onshore Wind PPA'
    | 'Off-Peak Future'
    | 'Baseload Future';

export const productTypeToPowerSupplierMap: Record<ProductType, PowerSupplier['type'] | undefined> = {
    trawa_fee: undefined,
    goo: undefined,
    balancing_product: 'spot',
    'Solar PPA': 'solar',
    'Peakload Future': 'futures',
    'Onshore Wind PPA': 'wind',
    'Off-Peak Future': 'futures',
    'Baseload Future': 'futures',
};

export const calculateResidualSpot = (
    powerSuppliers: PowerSupplier[],
    addresses: CompanyConsumption['addresses'],
): PowerSupplier[] => {
    const spotPowerSupplier = powerSuppliers.filter(powerSupplier => powerSupplier.type === 'spot')[0];
    // check if we already have calculated spot data
    if (!spotPowerSupplier || spotPowerSupplier.kwh !== null) return powerSuppliers;

    const totalConsumptionKwh = addresses.reduce((acc, address) => acc + address.kwh, 0);

    const nonSpotPowerSuppliers = powerSuppliers.filter(powerSupplier => powerSupplier.type !== 'spot');

    // check if we have data for all other power suppliers
    if (nonSpotPowerSuppliers.some(powerSupplier => powerSupplier.kwh === null)) {
        // we don't have enough data to calculate residual
        return powerSuppliers;
    }

    const totalSupplyKwh = nonSpotPowerSuppliers.reduce((acc, powerSupplier) => acc + (powerSupplier.kwh as number), 0);

    const residualSpotKwh = totalConsumptionKwh - totalSupplyKwh;

    const residualSpotPowerSupplier = {
        ...spotPowerSupplier,
        kwh: Math.abs(residualSpotKwh),
        direction: residualSpotKwh > 0 ? 'sending' : 'receiving',
    } as PowerSupplier;

    return [...nonSpotPowerSuppliers, residualSpotPowerSupplier];
};

export const composeData = (
    account: Account,
    products: Product[],
    addresses: CompanyConsumption['addresses'],
    supply: SupplyItem[],
    maxMarketLocationPeakKwh: number,
): CompanyConsumption => {
    const nonSupplyProducts = products
        .filter(product => !supply.some(x => x.productType === product.productType))
        .map(product => productTypeToPowerSupplierMap[product.productType as ProductType])
        .filter((powerSupplierType, index, self) => self.indexOf(powerSupplierType) === index) // filter duplicates
        .filter((powerSupplierType): powerSupplierType is PowerSupplier['type'] => powerSupplierType !== undefined)
        .map(powerSupplierType => ({
            id: powerSupplierType,
            type: powerSupplierType,
            kwh: null,
            direction: 'neutral',
        })) as PowerSupplier[];

    const supplyProducts = supply
        .map(x => {
            const baseValues = {
                id: x.productType,
                kwh: x.kwh,
            };
            switch (x.productType) {
                case 'Onshore Wind PPA':
                    return {
                        ...baseValues,
                        type: 'wind',
                        direction: 'sending',
                    } as PowerSupplier;
                case 'Solar PPA':
                    return {
                        ...baseValues,
                        type: 'solar',
                        direction: 'sending',
                    } as PowerSupplier;
                case 'balancing_product':
                    return {
                        ...baseValues,
                        kwh: Math.abs(x.kwh),
                        type: 'spot',
                        direction: x.kwh > 0 ? 'sending' : 'receiving',
                    } as PowerSupplier;
                case 'Baseload Future':
                case 'Off-Peak Future':
                case 'Peakload Future':
                    return {
                        ...baseValues,
                        type: 'futures',
                        direction: 'sending',
                    } as PowerSupplier;
                default:
                    // TODO: handle other product types
                    return undefined;
            }
        })
        .filter((x): x is NonNullable<typeof x> => x !== undefined)
        // aggregate same type of products (especially for futures)
        .reduce((acc, product) => {
            const existingProduct = acc.find(x => x.type === product.type);
            if (existingProduct) {
                return acc.map(x => {
                    if (x.type !== product.type) return x;

                    return {
                        ...x,
                        kwh: (x.kwh ?? 0) + (product.kwh ?? 0),
                    };
                });
            }
            return [...acc, product];
        }, [] as PowerSupplier[]);

    const powerSuppliers = calculateResidualSpot([...nonSupplyProducts, ...supplyProducts], addresses);

    return {
        id: account.id,
        companyName: account.name,
        maxMarketLocationPeakKwh,
        powerSuppliers,
        addresses,
    };
};

export type MarketLocationWithVolumeEntriesWithKwh = MarketLocationWithVolumeEntries & { kwh: number | undefined };

export const getMarketLocationsForAddress = (
    marketLocationsWithVolumeEntries: MarketLocation[],
    addressCity: string,
    mapFn: (marketLocation: MarketLocation) => MarketLocationWithVolumeEntriesWithKwh,
) => {
    const marketLocationsForAddress = marketLocationsWithVolumeEntries
        .filter(marketLocation => marketLocation.addressCity === addressCity)
        .map(mapFn)
        .map(marketLocation => {
            const direction: Direction = 'receiving';
            const kmhValue = marketLocation.kwh;

            // TODO: when we know the direction of the market location, we can use this
            // const pvDatakwh = (marketLocation.pvDatakwh && parseInt(marketLocation.pvDatakwh)) || 0;
            // direction = pvDatakwh > kmhValue ? 'sending' : 'receiving';

            // TODO: activate this when we know the direction of the market location
            // if (marketLocation.type !== 'Consumption') {
            //     direction = 'sending';
            // }

            return {
                id: marketLocation.id,
                label: marketLocation.label ?? 'TODO',
                type: marketLocation.meteringType as 'SLP' | 'RLM' | 'PV',
                kwh: kmhValue,
                direction: direction,
                hasPv: !!marketLocation.pvData && marketLocation.pvData.length > 0,
                pvDatakwh: marketLocation.pvData && marketLocation.pvDatakwh ? marketLocation.pvDatakwh : '',
                peakKwh: marketLocation.marketLocationPeak.kwh,
                percentageFromPeak: (100 * (marketLocation.kwh ?? 0)) / marketLocation.peakKwh,
            } as const;
        });

    const rlmMarketLocations = marketLocationsForAddress.filter(marketLocation => marketLocation.type === 'RLM');
    const slpMarketLocations = marketLocationsForAddress.filter(marketLocation => marketLocation.type === 'SLP');
    const pvMarketLocations = marketLocationsForAddress.filter(marketLocation => marketLocation.type === 'PV');

    const accumulatedSlpMarketLocation =
        slpMarketLocations.length > 0 &&
        slpMarketLocations.reduce(
            (acc, marketLocation) => {
                return {
                    ...marketLocation,
                    label: `${slpMarketLocations.length}`,
                    kwh: (acc.kwh ?? 0) + (marketLocation.kwh ?? 0),
                    peakKwh: Math.max(acc.peakKwh, marketLocation.peakKwh),
                    percentageFromPeak:
                        (100 * ((acc.kwh ?? 0) + (marketLocation.kwh ?? 0))) /
                        Math.max(acc.peakKwh, marketLocation.peakKwh),
                };
            },
            { ...slpMarketLocations[0], kwh: 0, peakKwh: 0, percentageFromPeak: 0 },
        );

    return [...rlmMarketLocations, ...pvMarketLocations, accumulatedSlpMarketLocation].filter(Boolean);
};

export type UseGridDataParams = {
    selectedDate: Temporal.PlainDate;
    localTimestamp: Temporal.PlainDateTime;
    groupId?: string;
};

export const useGridData = ({ selectedDate, localTimestamp, groupId }: UseGridDataParams) => {
    const contractPartnerId = useMaybeContractPartnerId();

    const {
        data: account,
        isLoading: isLoadingAccount,
        isError: isErrorAccount,
    } = trpc.account.getCurrent.useQuery({});

    const dateRange = useMemo(
        () => ({
            // add one day each cause API treads the end date as exclusive
            from: selectedDate.toString(),
            exclusiveTo: selectedDate.add({ days: 1 }).toString(),
        }),
        [selectedDate],
    );

    const {
        data: marketLocations = [],
        isLoading: isLoadingMarketLocations,
        isError: isErrorMarketLocations,
    } = trpc.dashboard.getMarketLocationsWithVolumeValues.useQuery(
        {
            contractPartnerId,
            dateRange,
            groupId: groupId && groupId !== '' ? groupId : undefined,
        },
        {},
    );

    const {
        data: products = [],
        isLoading: isLoadingProducts,
        isError: isErrorProducts,
    } = trpc.dashboard.getContractPartnerSupplyProducts.useQuery({ contractPartnerId }, {});

    const {
        data: supplyEntries = [],
        isLoading: isLoadingSupplyEntries,
        isError: isErrorSupplyEntries,
    } = trpc.consumptionGrid.getSupply.useQuery(
        {
            contractPartnerId,
            dateRange,
        },
        {},
    );

    const maxMarketLocationPeakKwh = Math.max(
        ...marketLocations.map(({ marketLocationPeak }) => marketLocationPeak.kwh),
    );

    // TODO: Unify this function with the one in useTVGrid.ts
    const addresses: CompanyConsumption['addresses'] = marketLocations
        .map(({ addressCity }) => addressCity)
        .filter((addressCity): addressCity is string => addressCity !== null)
        .filter((addressCity, index, self) => self.indexOf(addressCity) === index) // filter duplicates
        .map(addressCity => {
            const marketLocationsForAddress = getMarketLocationsForAddress(
                marketLocations,
                addressCity,
                // if there is a localTimestampStart, we can find the kwh for the market location at that time
                localTimestamp !== undefined
                    ? (marketLocation: MarketLocation) => {
                          return {
                              ...marketLocation,
                              kwh: marketLocation.volumeEntriesByMarketLocation?.find(volumeEntry => {
                                  const volumeEntryTemporal = getPlainDateTimeFromString(
                                      volumeEntry.timestampStart,
                                      marketLocation.timeZone,
                                  ).toString();

                                  return volumeEntryTemporal === localTimestamp.toString();
                              })?.kwh,
                              pvDatakwh: marketLocation.pvData
                                  ? marketLocation.pvData.find(pvData => {
                                        const volumeEntryTemporal = getPlainDateTimeFromString(
                                            pvData.timestampStart,
                                            marketLocation.timeZone,
                                        ).toString();

                                        return volumeEntryTemporal === localTimestamp.toString();
                                    })?.kwh
                                  : '',
                          } as MarketLocationWithVolumeEntriesWithKwh;
                      }
                    : ml => ml as MarketLocationWithVolumeEntriesWithKwh,
            );

            const kwhForAddress = marketLocationsForAddress.reduce((totalKwh, marketLocation) => {
                return totalKwh + (marketLocation.kwh ?? 0);
            }, 0);

            return {
                id: addressCity,
                name: addressCity,
                kwh: kwhForAddress,
                marketLocations: marketLocationsForAddress,
            };
        })
        .filter(address => address.marketLocations.length > 0);

    const timestampStartUtc = localTimestamp?.toZonedDateTime(TimeZones.EUROPE_BERLIN).epochMilliseconds;
    const supplyForTimestamp = timestampStartUtc
        ? supplyEntries.filter(x => new Date(x.timestampStart).getTime() === timestampStartUtc)
        : [];

    const composedData =
        account && composeData(account, products, addresses, supplyForTimestamp, maxMarketLocationPeakKwh);

    return {
        data: composedData,
        isLoading: isLoadingAccount || isLoadingMarketLocations || isLoadingProducts || isLoadingSupplyEntries,
        isError: isErrorAccount || isErrorMarketLocations || isErrorProducts || isErrorSupplyEntries,
    };
};
