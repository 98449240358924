import { useIntl } from 'react-intl';

export function DefaultNoDataAvailable() {
    const intl = useIntl();
    return (
        <div className="h-[230px] flex justify-center items-center">
            <span className="text-gray-5 font-bold text-base">{intl.formatMessage({ id: 'kpi.noDataAvailable' })}</span>
        </div>
    );
}
