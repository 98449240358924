import { trpc } from '../utils/trpc.js';
import { getPlainDateTimeFromString } from '../utils/getPlainDateTimeFromString';
import { Temporal } from 'temporal-polyfill';

export const useTotalConsumptionInfo = () => {
    const { data: totalConsumptions, isLoading } = trpc.dashboard.getTotalConsumption.useQuery({});

    const { totalKWh, firstIngestionDate: startDate } = totalConsumptions ?? {};
    if (!totalKWh || !startDate) {
        return {
            isLoading,
        };
    }
    const totalKWhValue = Number(totalKWh);
    const firstIngestionDate = getPlainDateTimeFromString(startDate).toPlainDate();
    const numberOfMonths = Temporal.PlainDate.from(firstIngestionDate).until(Temporal.Now.plainDateISO(), {
        largestUnit: 'months',
    }).months;

    return {
        totalConsumptions: totalKWhValue,
        averageConsumption: totalKWhValue / numberOfMonths,
        firstIngestionDate,
        isLoading,
    };
};
