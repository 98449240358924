type KPIHeaderProps = {
    name?: string;
    Header?: JSX.Element;
    children: JSX.Element | React.ReactNode;
    testId?: string;
};

export const Header = ({ children, testId }: KPIHeaderProps) => {
    return (
        <div className="flex-col" data-testid={`${testId}-header`}>
            {children}
        </div>
    );
};
