import { DefaultNoDataAvailable } from './DefaultNoDataAvailable';

type KPICardContentProps = {
    name: string;
    children: React.ReactNode;
    showEmpty?: boolean;
};

export const Content = ({ name, children, showEmpty = false }: KPICardContentProps) => {
    if (showEmpty) {
        return <DefaultNoDataAvailable />;
    }

    return (
        <section data-testid={`kpi-${name}-content`} className="relative -top-5 -left-3.5">
            {children}
        </section>
    );
};
