import { trpc } from '../utils/trpc.js';
import { AccountType, useUserSettings } from '../userSettings.ts';

export const useAccountUserInfo = () => {
    const { data: account } = trpc.account.getCurrent.useQuery({});
    const { userSettings } = useUserSettings();
    const { data: contractPartners } = trpc.dashboard.getContractPartners.useQuery({}, {});

    const isRealEstateUser = userSettings.accountType === AccountType.RealEstate;
    const contractPartnerName =
        isRealEstateUser &&
        contractPartners?.find(contractPartner => contractPartner.contractPartnerId === userSettings.contractPartnerId)
            ?.contractPartnerName;

    return { account, contractPartnerName, isRealEstateUser };
};
