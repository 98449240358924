import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import { AccountType, partialUserSettingsAtom } from '../userSettings';
import { useAtom } from 'jotai/react';
import { contractSelectionPath } from './router';
import { useEffect } from 'react';
import { MaintenanceBanner } from '../components/MaintenanceBanner';
import { config } from '../config';
import { useIntl } from 'react-intl';

export function AuthenticatedLayout() {
    const navigate = useNavigate();
    const [userSettings] = useAtom(partialUserSettingsAtom);
    const intl = useIntl();

    const needToSelectContract = userSettings.accountType === AccountType.RealEstate && !userSettings.contractPartnerId;

    useEffect(() => {
        if (needToSelectContract) {
            navigate(contractSelectionPath);
        }
    }, [needToSelectContract, navigate]);

    if (needToSelectContract) {
        return null;
    }

    return (
        <div className="flex w-full lg:pl-60 xl:pl-80">
            <Sidebar />
            <div
                className={`px-2 md:px-4 lg:px-8 ${
                    userSettings.accountType === AccountType.RealEstate ? 'py-44' : 'py-20'
                } lg:py-8 flex justify-center w-full min-h-screen`}
            >
                <div className="w-full">
                    {config.showMaintenanceBanner && (
                        <MaintenanceBanner
                            message={intl.formatMessage(
                                {
                                    id: 'maintenanceBanner.body',
                                },
                                {
                                    dayAndMonth: config.maintenanceDayAndMonth,
                                    startTime: config.maintenanceStartTime,
                                    endTime: config.maintenanceEndTime,
                                },
                            )}
                        />
                    )}
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
