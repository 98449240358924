import { InfoWindow, MarkerF } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { useState } from 'react';
import { Coordinates } from '../../types/locations';

export function SingleMarker({
    position,
    clusterer,
    name,
    icon,
}: {
    position: Coordinates;
    name: string;
    clusterer?: Clusterer;
    icon: string;
}) {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <MarkerF
            position={position}
            clusterer={clusterer}
            zIndex={clusterer ? 100 : 0}
            icon={{ url: icon }}
            onMouseOver={() => setShowInfo(true)}
            onMouseOut={() => setShowInfo(false)}
            title={name}
        >
            {showInfo && (
                <InfoWindow position={position} options={{ headerDisabled: true }}>
                    <h6 className="font-extrabold">{name}</h6>
                </InfoWindow>
            )}
        </MarkerF>
    );
}
