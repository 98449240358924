import { useEffect, useState } from 'react';
import { Temporal } from 'temporal-polyfill';
import { LoadingSpinner } from '@trawa-energy/ui-kit';
import { useGridData } from '../../hooks/useGridData.ts';
import { useIntl } from 'react-intl';
import { analytics } from '../../analytics.ts';
import { Mode } from './types.ts';
import { Suppliers } from './Suppliers.tsx';
import { ConsumerName } from './ConsumerName.tsx';
import { Consumptions } from './Consumptions.tsx';
import { GridContainer } from './GridContainer.tsx';
import { TimestampSlider } from './TimestampSlider.tsx';
import { DisplayGrid, useLastDayWithData } from '../../hooks/useLastDayWithData.ts';

export const ConsumptionGrid = ({ groupId }: { groupId?: string }) => {
    const intl = useIntl();
    const [mode, setMode] = useState<Mode>({
        id: '',
        type: 'default',
    });
    const [arrowsAreVisible, setArrowsAreVisible] = useState(true);
    const toggleArrowsAreVisible = () => {
        setArrowsAreVisible(prevState => !prevState);
    };

    const [selectedDate, setSelectedDate] = useState<Temporal.PlainDate>(
        Temporal.Now.plainDateISO().subtract({ days: 1 }),
    );
    const [localTimestamp, setLocalTimestamp] = useState<Temporal.PlainDateTime>(selectedDate.toPlainDateTime());

    useEffect(() => {
        setLocalTimestamp(selectedDate.toPlainDateTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate.toString()]);

    const {
        data: lastDayWithData,
        isLoading: isLoadingLastDayWithData,
        isError: isErrorLastDayWithData,
    } = useLastDayWithData({ display: DisplayGrid.DESKTOP });

    useEffect(() => {
        if (lastDayWithData) {
            setSelectedDate(lastDayWithData.toPlainDate());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastDayWithData?.toString()]);

    const {
        data,
        isLoading: isLoadingGridData,
        isError: isErrorGridData,
    } = useGridData({
        selectedDate,
        localTimestamp,
        groupId,
    });

    const isLoading = isLoadingLastDayWithData || isLoadingGridData;
    const isError = isErrorLastDayWithData || isErrorGridData;

    useEffect(() => {
        if (localTimestamp === undefined || intl.timeZone === undefined) {
            return;
        }

        analytics.track('Grid rendered', {
            Date: localTimestamp.toZonedDateTime(intl.timeZone).toString(),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTimestamp?.toPlainDate().toString()]);

    const paddingTailwindClasses = 'px-3 py-8 md:px-8';

    if (isLoading || data === undefined) {
        return (
            <GridContainer className={paddingTailwindClasses}>
                <LoadingSpinner />
            </GridContainer>
        );
    }

    if (isError) {
        return (
            <GridContainer className={`${paddingTailwindClasses} text-center`}>
                {intl.formatMessage({ id: 'overview.errorMsg' })}
            </GridContainer>
        );
    }

    return (
        <GridContainer>
            <div className={`w-full max-w-6xl 2xl:max-w-full flex flex-col items-center ${paddingTailwindClasses}`}>
                <Suppliers
                    mode={mode}
                    powerSuppliers={data.powerSuppliers}
                    endElementId={data.id}
                    maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                />
                <ConsumerName id={data.id} companyName={data.companyName} />
                <Consumptions
                    id={data.id}
                    mode={mode}
                    addresses={data.addresses}
                    setMode={setMode}
                    toggleArrowsAreVisible={toggleArrowsAreVisible}
                    arrowsAreVisible={arrowsAreVisible}
                    maxMarketLocationPeakKwh={data.maxMarketLocationPeakKwh}
                />
            </div>
            {selectedDate && localTimestamp && (
                <TimestampSlider
                    selectedDate={selectedDate}
                    setSelectedDate={(newSelectedDate: Temporal.PlainDate) => setSelectedDate(newSelectedDate)}
                    value={localTimestamp}
                    onChange={setLocalTimestamp}
                />
            )}
        </GridContainer>
    );
};
