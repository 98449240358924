import { useIntl } from 'react-intl';
import { Direction } from './types.ts';

/**
 * @example
 * (1) => [0]
 * (2) => [-1, 1]
 * (3) => [-2, 0, 2]
 * (4) => [-3, -1, 1, 3]
 */
export const getOffsetByNumOfNodes = (numOfNodes: number, factor = 4) =>
    Array(numOfNodes)
        .fill(undefined)
        .map((_, index) => (index - numOfNodes + 1 + index) * factor);

export const getGridBreak = (total: number, index: number, variant: 'topDown' | 'bottomUp' = 'topDown') => {
    if (total <= 3) {
        return 0;
    }

    const isFirst = index === 0;
    const isLast = total === index + 1;

    if (isFirst || isLast) {
        return variant === 'topDown' ? 7 : -7;
    }

    return variant === 'topDown' ? -7 : 7;
};

export const calcAnimationSpeedPercentage = (kwh: number | undefined, maxKwh: number) =>
    ((kwh ?? 0) * 100) / maxKwh || 0; // maxKwh can be 0 which results to NaN

export const useFormatKwh = () => {
    const { formatNumber } = useIntl();

    return (number: number) => {
        const formattedNumber = formatNumber(number, {
            maximumFractionDigits: number >= 10 ? 0 : 2,
        });

        return formattedNumber + ' kWh';
    };
};

export const directionToGradientMap: Record<Direction, string> = {
    neutral: 'hsl(var(--gray-2))',
    receiving: 'hsl(var(--primary-blue-4))',
    sending: 'linear-gradient(180deg, hsl(var(--solar)) 0%, hsl(var(--orange)) 100%)',
    unavailable: 'hsl(var(--gray-5))',
};

export const directionToColorMap: Record<Direction, string> = {
    neutral: 'hsl(var(--gray-2))',
    receiving: 'hsl(var(--primary-blue-4))',
    sending: 'hsl(var(--orange))',
    unavailable: 'hsl(var(--gray-5))',
};
