import { Temporal } from 'temporal-polyfill';

const getNthDateFromToday = (n: number, locale: string) => {
    const today = Temporal.Now.plainDateISO();
    const nthDay = today.add({ days: n });

    return nthDay.toLocaleString(locale, { month: 'numeric', day: 'numeric', year: 'numeric' });
};

export const getTomorrowsDate = (locale = 'de-DE') => getNthDateFromToday(1, locale);

export const getTodaysDate = (locale = 'de-DE') => getNthDateFromToday(0, locale);
