import React, { FC } from 'react';
import { AddressIcon } from '@trawa-energy/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { GridLine } from '../GridLine.tsx';
import { calcAnimationSpeedPercentage, getGridBreak, getOffsetByNumOfNodes } from './utils.ts';
import { MarketLocation as MarketLocationT, Address, Mode } from './types.ts';
import { MarketLocation } from './MarketLocation.tsx';
import { Box } from './Box.tsx';
import { PowerPill } from './PowerPill.tsx';

type ConsumptionProps = {
    addresses: Address[];
    mode: Mode;
    setMode: React.Dispatch<React.SetStateAction<Mode>>;
    toggleArrowsAreVisible: () => void;
    arrowsAreVisible: boolean;
    maxMarketLocationPeakKwh: number;
    id: string;
};
export const Consumptions: FC<ConsumptionProps> = ({
    addresses,
    maxMarketLocationPeakKwh,
    mode,
    setMode,
    toggleArrowsAreVisible,
    arrowsAreVisible,
    id,
}) => {
    return (
        <AnimatePresence>
            <div className="flex justify-around gap-6 2xl:gap-12 w-full">
                {addresses.map((address, index) => {
                    const isVisible = mode.type === 'default' || mode.id === address.id;

                    return (
                        isVisible && (
                            <motion.div
                                key={address.id}
                                layout
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                // TODO: remove unused code on the grid
                                // onLayoutAnimationStart={() => {
                                //     toggleArrowsAreVisible();
                                // }}
                                // onLayoutAnimationComplete={() => {
                                //     toggleArrowsAreVisible();
                                // }}
                            >
                                <div className="flex flex-col items-center mb-6 xl:mb-16 2xl:mb-20">
                                    <button
                                        type="button"
                                        disabled // TODO disabled until needed
                                        onClick={() => {
                                            setMode({
                                                id: address.id,
                                                type: 'address',
                                            });
                                        }}
                                    >
                                        <PowerPill
                                            value={address.kwh}
                                            direction={'receiving'}
                                            className="mb-4 xl:opacity-0"
                                        />
                                        <Box
                                            id={address.id}
                                            className="w-28 2xl:w-36 flex-col items-center px-4 py-2 2xl:px-6 2xl:py-4"
                                        >
                                            <AddressIcon className="w-6 2xl:w-10" />
                                            <span className="font-bold 2xl:text-xl">{address.name}</span>
                                        </Box>
                                    </button>
                                </div>
                                <div
                                    className={`hidden xl:grid gap-6 2xl:gap-12 grid-cols-${address.marketLocations.length}`}
                                >
                                    {address.marketLocations
                                        // TODO replace with .toSorted() when supported
                                        .sort((x, y) => {
                                            const order: MarketLocationT['type'][] = ['RLM', 'PV', 'SLP'];

                                            return order.indexOf(x.type) - order.indexOf(y.type);
                                        })
                                        .map((marketLocation, index) => {
                                            return (
                                                <MarketLocation
                                                    id={marketLocation.id}
                                                    key={marketLocation.id}
                                                    startElementId={address.id}
                                                    marketLocation={marketLocation}
                                                    index={index}
                                                    direction={marketLocation.direction}
                                                    numberOfMarketLocations={address.marketLocations.length}
                                                    maxMarketLocationPeakKwh={maxMarketLocationPeakKwh}
                                                />
                                            );
                                        })}
                                </div>
                                <AnimatePresence initial={false}>
                                    {arrowsAreVisible && (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.4 }}
                                        >
                                            <GridLine
                                                start={id}
                                                end={address.id}
                                                startAnchor={{
                                                    position: 'bottom',
                                                    offset: {
                                                        x: getOffsetByNumOfNodes(addresses.length)[index],
                                                        y: 0,
                                                    },
                                                }}
                                                endAnchor="top"
                                                animationSpeedPercentage={calcAnimationSpeedPercentage(
                                                    address.kwh,
                                                    maxMarketLocationPeakKwh,
                                                )}
                                                dashColor="hsl(var(--primary-blue-4))"
                                                gridBreak={getGridBreak(addresses.length, index, 'bottomUp')}
                                            />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        )
                    );
                })}
            </div>
        </AnimatePresence>
    );
};
