import { DropdownField } from '@trawa-energy/ui-kit';
import { LoginContainer } from './login/LoginContainer';
import { trpc } from '../utils/trpc';
import { useIntl } from 'react-intl';
import { AccountType, partialUserSettingsAtom } from '../userSettings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAtom } from 'jotai/react';

export function ContractSelection() {
    const intl = useIntl();
    const [userSettings, setUserSettings] = useAtom(partialUserSettingsAtom);
    const { data: contractPartners } = trpc.dashboard.getContractPartners.useQuery({}, {});

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('returnUrl') ?? '/';

    return (
        <LoginContainer>
            <h1 className="text-2xl md:text-3xl font-bold pb-8 pt-16 md:pt-0 max-w-sm">
                {intl.formatMessage({ id: 'login.heading' })}
            </h1>
            <div>
                {contractPartners && (
                    <DropdownField
                        label={intl.formatMessage({ id: 'contractSelect.label' })}
                        placeholder={intl.formatMessage({ id: 'contractSelect.placeholder' })}
                        options={contractPartners.map(contract => ({
                            text: contract.contractPartnerName,
                            value: contract.contractPartnerId,
                        }))}
                        onChange={x => {
                            setUserSettings(settings => ({
                                ...settings,
                                accountType: AccountType.RealEstate,
                                contractPartnerId: x!.value,
                            }));
                            navigate(returnUrl);
                        }}
                        value={
                            (userSettings &&
                                userSettings.accountType === AccountType.RealEstate &&
                                userSettings.contractPartnerId) ||
                            null
                        }
                    />
                )}
            </div>
        </LoginContainer>
    );
}
