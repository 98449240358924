import { useUserSettings } from '../userSettings.ts';
import { useFlag, useStatus } from '@featurevisor/react';
import { useMemo } from 'react';

export const useFeatureFlags = () => {
    const { userSettings } = useUserSettings();
    const { isReady } = useStatus();
    const context = useMemo(() => ({ accountId: userSettings.accountId }), [userSettings.accountId]);

    const isClusterEnabled = useFlag('portalClusterView', context);
    const isManageOnly = useFlag('manageCustomer', context);
    const isDemo = useFlag('demoCustomer', context);
    const isNewReleases = useFlag('portalNewReleases', context);
    const isExcludedFromDataMissing = useFlag('dataMissingCustomer', context);
    const isSoonToSupply = useFlag('soonToSupplyCustomer', context);

    return useMemo(
        () => ({
            isBeta: isDemo,
            isManageOnly,
            isClusterEnabled,
            isReady,
            isNewReleases,
            isExcludedFromDataMissing,
            isSoonToSupply,
        }),
        [isDemo, isManageOnly, isClusterEnabled, isReady, isNewReleases, isExcludedFromDataMissing, isSoonToSupply],
    );
};
