import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { BurgerMenuIcon, DropdownField, TrawaLogoIcon } from '@trawa-energy/ui-kit';
import { trpc } from '../utils/trpc';
import { AccountType, useUserSettings } from '../userSettings.ts';
import { NavItem, useNavItems } from '../hooks/useNavItems.tsx';
import { SupportWidget } from './support-widget/SupportWidget.tsx';

export function Sidebar() {
    const intl = useIntl();
    const { data: contractPartners } = trpc.dashboard.getContractPartners.useQuery({}, {});
    const [showNav, setShowNav] = useState(false);
    const { navigationItems, isReady } = useNavItems();

    const navSections = {
        overview: [navigationItems['overview']],
        energyDelivery: [navigationItems['consumption'], navigationItems['products'], navigationItems['locations']],
        energyAssistant: [
            navigationItems['analysis'],
            navigationItems['costPrediction'],
            navigationItems['exchangePrices'],
            navigationItems['pvGeneration'],
        ],
        account: [navigationItems['marketLocations'], navigationItems['documents']],
    };

    const { userSettings, setUserSettings } = useUserSettings();
    const isRealEstateUser = userSettings!.accountType === AccountType.RealEstate;

    return (
        <div
            data-testid="sidebar"
            className="sidebar theme-container dark bg-background text-foreground fixed z-50 min-h-[3.5rem] lg:min-h-screen w-full lg:w-60 xl:w-80 left-0 p-4 lg:py-8 max-h-[100vh]"
        >
            <div className="flex justify-between items-center lg:block pb-3 lg:pb-7">
                <TrawaLogoIcon className="h-auto w-16 lg:w-28 text-foreground" />
                <button className="lg:hidden" onClick={() => setShowNav(!showNav)}>
                    <BurgerMenuIcon className="text-primary" />
                </button>
            </div>
            <div className="max-w-sm">
                {isRealEstateUser && contractPartners && (
                    <DropdownField
                        label={intl.formatMessage({ id: 'contractSelect.label' })}
                        placeholder={intl.formatMessage({ id: 'contractSelect.placeholder' })}
                        options={contractPartners.map(contract => ({
                            text: contract.contractPartnerName,
                            value: contract.contractPartnerId,
                        }))}
                        onChange={x =>
                            setUserSettings(settings => ({
                                ...settings,
                                accountType: AccountType.RealEstate,
                                contractPartnerId: x!.value,
                            }))
                        }
                        value={
                            (userSettings &&
                                userSettings.accountType === AccountType.RealEstate &&
                                userSettings.contractPartnerId) ||
                            null
                        }
                    />
                )}
            </div>
            <div
                className={`lg:flex flex-col gap-8 pt-4 h-[calc(100vh-4rem)] justify-between ${showNav ? 'flex' : 'hidden'}`}
            >
                {isReady && (
                    <div>
                        <div className="flex flex-col nav-items">
                            {Object.entries(navSections).map(([key, val], i) => (
                                <NavSection key={i} items={val} section={i ? key : ''} />
                            ))}
                            <SupportWidget />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const NavSection: FC<{ items: NavItem[]; section?: string }> = ({ items, section }) => {
    const intl = useIntl();
    return (
        // items array has at least one item visible to the current user
        items.some(item => !item.hidden) && (
            <div>
                {section && <div className="text-gray-2">{intl.formatMessage({ id: `sidebar.${section}` })}</div>}
                {items
                    .filter(item => !item.hidden)
                    .map(item => (
                        <NavLink
                            key={item.id}
                            to={item.path}
                            className="flex items-center gap-2 xl:text-lg 2xl:text-xl font-bold px-2 py-1 my-3 border-2 border-background hover:bg-primary hover:text-background rounded-full transition"
                        >
                            {item.icon}
                            <span>{intl.formatMessage({ id: item.translationKey })}</span>
                        </NavLink>
                    ))}
            </div>
        )
    );
};
