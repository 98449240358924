import { InfoIcon } from '@trawa-energy/ui-kit';

export function MaintenanceBanner({ message }: { message: React.ReactNode }) {
    return (
        <div className="banner-info py-2 px-4 mb-4 flex gap-3 rounded-xl">
            <InfoIcon className="w-10 h-10" />
            <p>{message}</p>
        </div>
    );
}
