import { MessageDescriptor } from 'react-intl';

export function formatPriceString(
    price: number,
    unit: string,
    locale: string,
    formatMessage: (descriptor: MessageDescriptor) => string,
) {
    return `${price.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${formatMessage({ id: `common.${unit}` })}`;
}
