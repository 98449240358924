import { ExternalLinkIcon } from '@trawa-energy/ui-kit';
import { Link, redirect } from 'react-router-dom';

export const KPICardFooter = ({ url, text }: { url: string; text: string }) => {
    return (
        <div
            className="flex gap-0.5 hover:cursor-pointer"
            onClick={() => {
                redirect(url);
            }}
        >
            <Link to={url}>
                <span className="underline underline-offset-2 decoration-primary-blue decoration-2 text-xs md:text-sm xl:text-base font-bold font-sans">
                    {text}
                </span>
            </Link>
            <ExternalLinkIcon />
        </div>
    );
};
