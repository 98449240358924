/**
 * This component is responsible for requesting a magic link, not for handling it.
 * States:
 * - NO_SIGNIN_LINK: Initial state - form
 * - REQUESTING_SIGNIN_LINK: requesting link in progress
 * - SIGNIN_LINK_REQUESTED: Link requested
 * - SIGNIN_LINK_REQUEST_FAILED: Link request failed
 */

import { LinkRequestForm } from './LinkRequestForm.tsx';
import { useState } from 'react';
import { LinkRequestFailed } from './LinkRequestFailed.tsx';
import { LinkRequestSucceeded } from './LinkRequestSucceeded.tsx';

export function MagicLinkCreation({
    signingInStatus,
    lastError,
    onSubmit,
}: {
    signingInStatus: MagicLinkCreationStatus;
    lastError: Error | undefined;
    onSubmit: (email: string) => void;
}) {
    const [wantToTyAgain, setWantToTryAgain] = useState(false);

    if (signingInStatus === 'SIGNIN_LINK_REQUESTED' && !wantToTyAgain) {
        return <LinkRequestSucceeded onTryAgain={() => setWantToTryAgain(true)} />;
    }

    // TODO: https://github.com/trawa-energy/stalk/issues/1514
    if (lastError instanceof Error && lastError.name === 'NotAuthorizedException' && !wantToTyAgain) {
        return <LinkRequestSucceeded onTryAgain={() => setWantToTryAgain(true)} />;
    }

    if (signingInStatus === 'SIGNIN_LINK_REQUEST_FAILED' && !wantToTyAgain) {
        return <LinkRequestFailed error={lastError} onTryAgain={() => setWantToTryAgain(true)} />;
    }

    return (
        <LinkRequestForm
            busy={signingInStatus === 'REQUESTING_SIGNIN_LINK'}
            onSubmit={email => {
                onSubmit(email); // here signingInStatus should change, so we can remove wantToTyAgain if it was set
                setWantToTryAgain(false);
            }}
        />
    );
}

const magicLinkCreationStates = [
    'NO_SIGNIN_LINK',
    'REQUESTING_SIGNIN_LINK',
    'SIGNIN_LINK_REQUESTED',
    'SIGNIN_LINK_REQUEST_FAILED',
] as const;
type MagicLinkCreationStatus = (typeof magicLinkCreationStates)[number];

MagicLinkCreation.isValidStatus = (status: string): status is MagicLinkCreationStatus => {
    return magicLinkCreationStates.includes(status as MagicLinkCreationStatus);
};
