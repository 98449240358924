import { KPICard } from '../../kpi-card/KPICard';
import { useIntl } from 'react-intl';
import { ChartLineIcon } from '@trawa-energy/ui-kit';
import { ConsumptionChart } from '../../charts/ConsumptionChart';
import { formatEnergy } from '../../../utils/formatting/formatPowerConsumption';
import { Temporal } from 'temporal-polyfill';
import { KPICardFooter } from './KPICardFooter';
import { useVolumeEntries } from '../../../hooks/useVolumeEntries';
import { useState } from 'react';
import { KPICardHeader } from './KPICardHeader';

type ConsumptionCardProps = {
    totalConsumptions?: number;
    averageConsumption?: number;
    firstIngestionDate?: Temporal.PlainDate;
    isLoading: boolean;
};

export const ConsumptionCard = ({
    totalConsumptions,
    averageConsumption,
    firstIngestionDate,
    isLoading,
}: ConsumptionCardProps) => {
    const intl = useIntl();

    const [today] = useState(Temporal.Now.plainDateISO(intl.timeZone!));
    const { yearMonths, consumptions } = useVolumeEntries(today);
    const isTotalConsumptionDataReady = !!(totalConsumptions && averageConsumption && firstIngestionDate);
    const title = intl.formatMessage({ id: 'kpi.consumption.title' });
    const subtitle = isTotalConsumptionDataReady
        ? intl.formatMessage(
              { id: 'kpi.consumption.subtitle' },
              {
                  totalConsumptions: formatEnergy(totalConsumptions, intl.locale),
                  averageConsumption: formatEnergy(averageConsumption, intl.locale),
                  firstIngestionDate: intl.formatDate(firstIngestionDate.toString(), {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                  }),
              },
          )
        : intl.formatMessage({ id: 'kpi.consumption.noDataAvailableSubtitle' });

    const name = 'kpi-consumption';

    return (
        <KPICard.Root name={name} isLoading={isLoading}>
            <KPICard.Header testId={name}>
                <KPICardHeader title={title} subtitle={subtitle} Icon={ChartLineIcon} />
            </KPICard.Header>

            <KPICard.Content name={name} showEmpty={!consumptions || !isTotalConsumptionDataReady}>
                <ConsumptionChart
                    height={'230px'}
                    isLoading={isLoading}
                    isTotalConsumptionDataReady={isTotalConsumptionDataReady}
                    consumptions={consumptions}
                    yearMonths={yearMonths}
                    today={today}
                />
            </KPICard.Content>

            <KPICard.Footer name={name}>
                <KPICardFooter text={intl.formatMessage({ id: 'kpi.consumption.linkText' })} url="/consumption" />
            </KPICard.Footer>
        </KPICard.Root>
    );
};
