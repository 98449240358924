import { useEffect, useMemo, useState } from 'react';
import { FormatDateOptions, IntlShape, useIntl } from 'react-intl';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card } from './Card';
import { Button, ChevronDownIcon, ChevronUpIcon, Toggle } from '@trawa-energy/ui-kit';
import { MapWithSingleMarker } from './maps/MapWithSingleMarker.tsx';
import { MarketLocationViewType } from '../pages/ConsumptionView.tsx';
import { Interval, Period } from '../utils/useFilterValues';
import { DateRange } from './CalendarWrapper';
import { analytics } from '../analytics';
import { formatAxisDateTimeByInterval } from '../utils/formatting/formatAxisDateTimeInterval.ts';
import { formatTooltipDateTimeByInterval } from '../utils/formatting/formatTooltipDateTimeByInterval.ts';

type VolumeDataItem = {
    timestampStart: string;
    kwh: number;
};

const DisplayType = {
    chart: 0,
    table: 1,
} as const;

const displayTypes = Object.keys(DisplayType) as (keyof typeof DisplayType)[];

export function StatsCard({
    data,
    interval,
    lastIngestionDate,
    selectedDates,
    period,
    coordinates,
    timeZone,
    view,
}: {
    data: VolumeDataItem[];
    interval: Interval;
    lastIngestionDate: string | null;
    selectedDates: DateRange;
    period: Period;
    coordinates: { lat: number; lng: number } | null;
    timeZone: string;
    view: MarketLocationViewType;
}) {
    const intl = useIntl();
    const [displayType, setDisplayType] = useState<0 | 1>(DisplayType.chart);
    const [tableShowAll, setTableShowAll] = useState(false);

    const aggregatedEntries = useMemo(
        () =>
            data.map(item => ({
                x: new Date(item.timestampStart).getTime(),
                y: item.kwh,
            })),
        [data],
    );

    const chartOptions = useMemo(
        () => createChartOptions(intl, interval, aggregatedEntries, period, timeZone),
        [intl, interval, aggregatedEntries, period, timeZone],
    );

    useEffect(() => {
        if (aggregatedEntries) {
            analytics.track('Report rendered', {
                'Report Name': 'Actual Consumption',
                'Time Interval': interval,
                'Time Preset': period,
                'Date Range': `${selectedDates.from.toString()}-${selectedDates.to.toString()}`,
                'Report View Type': displayTypes[displayType],
            });
        }
    }, [aggregatedEntries, period, selectedDates, interval, displayType]);

    const title = () => {
        const dayFormat = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' } as FormatDateOptions;
        if (selectedDates.from.equals(selectedDates.to)) {
            return (
                intl.formatMessage({ id: 'common.on' }) +
                ' ' +
                selectedDates.from.toLocaleString(intl.locale, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                })
            );
        } else {
            return period.includes('Month')
                ? // e.g. in December 2023
                  intl.formatMessage({ id: 'common.in' }) +
                      ' ' +
                      intl.formatMessage({ id: `common.months.${selectedDates.from.month - 1}` }) +
                      ' ' +
                      selectedDates.from.year
                : // e.g. from [day] to [day]
                  intl.formatMessage({ id: 'common.from' }) +
                      ' ' +
                      selectedDates.from.toLocaleString(intl.locale, dayFormat) +
                      ' ' +
                      intl.formatMessage({ id: 'common.to' }) +
                      ' ' +
                      selectedDates.to.toLocaleString(intl.locale, dayFormat);
        }
    };

    const total = () => {
        const totalKwh = [...aggregatedEntries].reduce((a, b) => a + b.y, 0);
        return (
            intl.formatNumber(totalKwh > 1000 ? totalKwh / 1000 : totalKwh, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }) + (totalKwh > 1000 ? ' MWh' : ' kWh')
        );
    };

    return (
        <Card padding="p-0" className="overflow-auto">
            <div className="flex flex-col-reverse md:flex-row">
                <div className="p-3 md:p-8 md:w-2/3 flex flex-wrap justify-between items-center gap-2 min-h-[410px] overflow-x-hidden">
                    <h3 className="text-base md:text-lg xl:text-xl font-bold w-full">
                        {intl.formatMessage({ id: 'consumptionView.consumption' })} {title()}
                    </h3>
                    <h4 className="text-sm lg:text-base 2xl:text-lg">
                        {intl.formatMessage({ id: 'common.total' })}: {total()}
                    </h4>
                    {aggregatedEntries.length > 0 ? (
                        <>
                            <div className="text-sm 2xl:text-base">
                                <Toggle
                                    items={[
                                        <span key={1}>
                                            {intl.formatMessage({ id: `consumptionView.${displayTypes[0]}` })}
                                        </span>,
                                        <span key={2}>
                                            {intl.formatMessage({ id: `consumptionView.${displayTypes[1]}` })}
                                        </span>,
                                    ]}
                                    onChange={setDisplayType}
                                />
                            </div>
                            {displayType === DisplayType.table ? (
                                <div className="lg:text-xl rounded-md border border-gray-3 w-full text-center overflow-hidden">
                                    <div className="grid grid-cols-2 font-bold p-4 bg-primary-1">
                                        <div>{intl.formatMessage({ id: `filterControls.intervals.${interval}` })}</div>
                                        <div>kWh</div>
                                    </div>
                                    <div className="max-h-[50vh] overflow-auto">
                                        {data &&
                                            aggregatedEntries.slice(0, tableShowAll ? undefined : 3).map(d => (
                                                <div key={d.x} className="grid grid-cols-2 p-4 border-t border-gray-3">
                                                    <div>
                                                        {formatTooltipDateTimeByInterval(
                                                            intl,
                                                            new Date(d.x),
                                                            interval,
                                                            timeZone,
                                                        )}
                                                    </div>
                                                    <div>{d.y.toFixed(2)}</div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full relative mt-2">
                                    <div className="text-gray-5 text-xs font-bold absolute left-4">kWh</div>
                                    {/* A bug: chart doesn't rerender when chart type is updated: https://github.com/apexcharts/react-apexcharts/issues/165 */}
                                    <ReactApexChart
                                        key={view}
                                        options={chartOptions}
                                        series={[{ data: aggregatedEntries }]}
                                        type={view as 'bar' | 'line'}
                                        height={chartOptions.chart?.height}
                                    />
                                    <div className="text-gray-5 text-xs font-bold absolute w-full text-center left-4 bottom-2">
                                        {intl.formatMessage({ id: `filterControls.intervals.${interval}` })}
                                    </div>
                                </div>
                            )}
                            <div className="text-right text-xs">
                                {intl.formatMessage({ id: 'consumptionView.lastUpdated' })}:{' '}
                                {lastIngestionDate && intl.formatDate(lastIngestionDate, { dateStyle: 'medium' })}
                            </div>
                            {displayType === DisplayType.table && aggregatedEntries.length > 3 && (
                                <Button
                                    variant="link"
                                    className="font-bold"
                                    iconRight={
                                        tableShowAll ? (
                                            <ChevronUpIcon className="text-primary" />
                                        ) : (
                                            <ChevronDownIcon className="text-primary" />
                                        )
                                    }
                                    onClick={() => setTableShowAll(!tableShowAll)}
                                >
                                    <span>
                                        {intl.formatMessage({
                                            id: `consumptionView.display${tableShowAll ? 'Less' : 'All'}`,
                                        })}
                                    </span>
                                </Button>
                            )}
                        </>
                    ) : (
                        <div className="flex place-content-center place-items-center text-lg font-bold h-full w-full text-gray-4">
                            {intl.formatMessage({ id: 'common.noData' })}
                        </div>
                    )}
                </div>
                {coordinates && (
                    <div className="bg-gray-2 w-full md:w-1/3 h-60 md:h-auto min-h-[240px]">
                        <MapWithSingleMarker coordinate={coordinates} />
                    </div>
                )}
            </div>
        </Card>
    );
}

function createChartOptions(
    intl: IntlShape,
    interval: Interval,
    aggregatedEntries: { x: number; y: number }[],
    period: Period,
    timeZone: string,
): ApexOptions {
    return {
        chart: {
            height: 254,
            animations: {
                enabled: false,
            },
            toolbar: {
                tools: {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#0558C7'],
        yaxis: {
            axisBorder: {
                show: true,
                color: '#A6A6A6',
                width: 2,
            },
            labels: {
                style: {
                    colors: '#737373',
                    fontSize: '12px',
                },
                formatter: (val: number) =>
                    intl.formatNumber(Math.round(val), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                    }),
            },
        },
        grid: {
            padding: {
                left: 20,
                right: 40, // Also you may want to increase this (based on the length of your labels)
            },
        },
        xaxis: {
            type: 'datetime',
            tickAmount: aggregatedEntries.length > 10 ? undefined : 'dataPoints',
            axisBorder: {
                show: true,
                color: '#A6A6A6',
                strokeWidth: 2,
            },
            labels: {
                style: {
                    colors: '#737373',
                    fontSize: '12px',
                },
                rotateAlways:
                    (!period.includes('Month') && interval === 'day' && aggregatedEntries.length > 8) ||
                    interval === 'month' ||
                    (interval === '15min' && aggregatedEntries.length > 100) ||
                    (interval === 'hour' && aggregatedEntries.length > 24),
                minHeight: 32,
                offsetY: -3,
                formatter: (value, _, opts: { i: number }) => {
                    return formatAxisDateTimeByInterval(
                        intl,
                        new Date(value),
                        interval,
                        aggregatedEntries.length,
                        period,
                        timeZone,
                    );
                },
            },
        },
        tooltip: {
            x: {
                formatter: value => {
                    return formatTooltipDateTimeByInterval(intl, new Date(value), interval, timeZone);
                },
            },
            y: {
                formatter: (value: number) => {
                    return (
                        intl.formatNumber(value, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        }) + ' kWh'
                    );
                },
                title: {
                    formatter: (seriesName: string) => {
                        return '';
                    },
                },
            },
        },
    };
}
