import { useMemo } from 'react';
import { KPICard } from '../../kpi-card/KPICard';
import { useIntl } from 'react-intl';
import { ExchangePriceChart } from '../../charts/ExchangePriceChart';
import { YAXisComponentOption, EChartsOption } from 'echarts';
import { Temporal } from 'temporal-polyfill';
import { getPlainDateTimeFromString } from '../../../utils/getPlainDateTimeFromString';
import { PriceItem, PricesType } from '../../../types/prices';
import { DollarIcon } from '@trawa-energy/ui-kit';
import { KPICardFooter } from './KPICardFooter';
import { KPICardHeader } from './KPICardHeader';

type ExchangePriceCardProps = {
    isLoading: boolean;
    prices?: PricesType['data'];
};

export const ExchangePriceCard = ({ prices, isLoading }: ExchangePriceCardProps) => {
    const intl = useIntl();
    const xAxisData = useMemo(() => {
        if (prices === undefined) {
            return [];
        }
        const today = Temporal.Now.plainDateISO().toString();

        const filterData = (items: PricesType['data'][0]) => {
            return items.flatMap((item: PriceItem) => {
                if (getPlainDateTimeFromString(item.timestampStart).toString().includes(today)) {
                    return `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`;
                }
                return [];
            });
        };

        return [...filterData(prices[0]), ...filterData(prices[1])].sort();
    }, [prices, intl]);

    const xAxis = {
        type: 'category',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 10,
            interval: (index: number, value: string) => value === '00:00' || value === '12:00' || value === '23:00',
        },
        data: xAxisData,
    } as EChartsOption['xAxis'];

    const yAxis: YAXisComponentOption = {
        type: 'value',
        name: intl.formatMessage({ id: 'exchangePrices.yAxisLabel' }),
        nameGap: 20,
        nameTextStyle: {
            fontSize: 10,
            fontWeight: 'bold',
            color: 'black',
        },
        axisLabel: {
            fontSize: 10,
        },
    };

    const title = intl.formatMessage({ id: 'kpi.exchangePrice.title' });
    const subtitle = prices
        ? intl.formatMessage({ id: 'kpi.exchangePrice.subtitle' })
        : intl.formatMessage({ id: 'kpi.exchangePrice.noDataAvailableSubtitle' });

    const name = 'kpi-exchange-prices';

    return (
        <KPICard.Root name={name} isLoading={isLoading}>
            <KPICard.Header testId={name}>
                <KPICardHeader title={title} subtitle={subtitle} Icon={DollarIcon} />
            </KPICard.Header>
            <KPICard.Content name={name} showEmpty={!prices}>
                <ExchangePriceChart
                    prices={prices}
                    height={'230px'}
                    yAxis={yAxis}
                    xAxis={xAxis}
                    isLoading={isLoading}
                />
            </KPICard.Content>
            <KPICard.Footer name={name}>
                <KPICardFooter text={intl.formatMessage({ id: 'kpi.exchangePrice.linkText' })} url="/exchange-prices" />
            </KPICard.Footer>
        </KPICard.Root>
    );
};
