import { Box } from './Box.tsx';

export const ConsumerName = ({ id, companyName }: { id: string; companyName: string }) => {
    return (
        <div className="flex justify-center my-16 2xl:my-20">
            <button type="button" disabled={true}>
                <Box id={id} className="px-10 py-6 2xl:px-12 2xl:py-8">
                    <span className="font-bold 2xl:text-2xl">{companyName}</span>
                </Box>
            </button>
        </div>
    );
};
