/**
 * This file contains the UNIT class utility
 */
export enum KPIKeys {
    Consumption = 'consumption',
    Emission = 'emission',
    Solar = 'solar',
}

export enum UnitKeys {
    Kwh = 'kWh',
    MWh = 'MWh',
    GWh = 'GWh',
    Tones = 't',
}

export type KPIType = `${KPIKeys}`;
export type ValueAndUnitType = {
    value: number;
    unit: string;
};

export const EMPTY_VALUE = { value: 0, unit: '' };

export const getConsumptionValue = (value: number): ValueAndUnitType => {
    if (value < 1000) {
        return { value, unit: UnitKeys.Kwh };
    }

    if (value < 1000000) {
        return { value: value / 1000, unit: UnitKeys.MWh };
    }

    return { value: value / 1000000, unit: UnitKeys.GWh };
};

export const getEmissionValue = (value: number) => {
    return { value: value / 1000000, unit: UnitKeys.Tones };
};

export const getValueAndUnitKPI = (type: KPIType, value: number) => {
    const valueAndUnit: Record<KPIKeys, ValueAndUnitType> = {
        [KPIKeys.Consumption]: getConsumptionValue(value),
        [KPIKeys.Emission]: getEmissionValue(value),
        [KPIKeys.Solar]: EMPTY_VALUE,
    };

    return valueAndUnit[type];
};

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UNIT {
    static fromKPITypeToUnit = getValueAndUnitKPI;
}
