import { IntlShape } from 'react-intl';

export const formatValue = (value: unknown, intl: IntlShape) => {
    const floatValue = typeof value === 'string' ? parseFloat(value) : value;

    if (floatValue === undefined || typeof floatValue !== 'number' || isNaN(floatValue)) {
        return '';
    }

    return intl.formatNumber(Math.abs(floatValue) < 0.01 ? 0 : floatValue, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
