import { useIntl } from 'react-intl';
import { useAccountUserInfo } from '../hooks/useAccountUserInfo.ts';

export const PageHeading = ({ title }: { title: string }) => {
    const intl = useIntl();
    const { isRealEstateUser, account, contractPartnerName } = useAccountUserInfo();

    return (
        <div className="mb-6">
            <h1 className="text-2xl md:text-[2rem] font-bold">
                {intl.formatMessage({ id: title })} {account?.name}
            </h1>
            {isRealEstateUser && (
                <div className="mt-2 flex gap-4">
                    <h2>{intl.formatMessage({ id: 'realEstate.subtext.text' }, { partner: contractPartnerName })}</h2>
                </div>
            )}
        </div>
    );
};
