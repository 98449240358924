import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card } from '../components/Card';
import { StatsCard } from '../components/StatsCard';
import { StatsCardSkeleton } from '../components/StatsCardSkeleton';
import { trpc } from '../utils/trpc';
import { useFilterValues } from '../utils/useFilterValues';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { useGetMarketLocations } from '../hooks/useMarketLocations';
import { AccountType, useUserSettings } from '../userSettings.ts';
import { PageHeading } from '../components/PageHeading.tsx';
import { getSelectedDatesByFilterValues } from '../utils/getSelectedDatesByFilterValues.ts';
import { DateRangeFilterControls } from '../components/filter-controls/DateRangeFilterCotrols.tsx';
import { IntervalFilterControls } from '../components/filter-controls/IntervalFilterControls.tsx';
import { getAddress } from '../utils/formatting/formatGetAddress.ts';

export type MarketLocationViewType = 'bar' | 'line' | 'table';

const marketLocationTypeMap = new Map<MarketLocation['type'], number>([
    ['Consumption', 2], // consumption comes first
    ['FeedIn', 1], // PV comes second
    [null, 0], // null comes last
]);

export function ConsumptionView() {
    const intl = useIntl();
    const [filterValues, setFilterValues] = useFilterValues();
    const selectedDates = useMemo(() => getSelectedDatesByFilterValues(filterValues), [filterValues]);
    const { data: filteredMarketLocations } = useGetMarketLocations();

    const volumeCurveIds = filteredMarketLocations?.map(ml => ml.volumeCurveId) ?? [];
    const { userSettings } = useUserSettings();
    const contractPartnerId =
        userSettings.accountType === AccountType.RealEstate ? userSettings.contractPartnerId : undefined;

    const { data: lastIngestionDates } = trpc.dashboard.getLastIngestionDates.useQuery({ contractPartnerId });
    const { data: volumeEntries } = trpc.dashboard.getVolumeEntries.useQuery(
        {
            dateRange: {
                from: selectedDates.from.toString(),
                exclusiveTo: selectedDates.exclusiveTo.toString(),
            },
            contractPartnerId,
            aggregationInterval: filterValues.interval === '15min' ? undefined : filterValues.interval,
        },
        { enabled: !!filteredMarketLocations, staleTime: 1000 * 60 * 5 },
    );

    const volumeEntriesByMarketLocations = useMemo(() => {
        return volumeEntries && filteredMarketLocations
            ? filteredMarketLocations
                  .map(marketLocation => ({
                      marketLocation,
                      entries: volumeEntries.filter(x => x.volumeCurveId === marketLocation.volumeCurveId),
                  }))
                  .sort(
                      (a, b) =>
                          marketLocationTypeMap.get(b.marketLocation.type)! -
                              marketLocationTypeMap.get(a.marketLocation.type)! ||
                          b.marketLocation.expectedYearlyVolumeKwh - a.marketLocation.expectedYearlyVolumeKwh,
                  ) // sorted by annual consumption
            : undefined;
    }, [volumeEntries, filteredMarketLocations]);

    const slpLocations = useMemo(() => {
        return volumeEntriesByMarketLocations
            ?.filter(a => a.marketLocation.meteringType === 'SLP')
            .sort((a, b) => (a.marketLocation.label || '').localeCompare(b.marketLocation.label || ''));
    }, [volumeEntriesByMarketLocations]);

    return (
        <div>
            <div className="flex flex-col gap-8 pb-6">
                <div id="consumption-page-header" className="consumption-page-header">
                    <PageHeading title={'consumptionView.title'} />
                    <div>
                        <Card className="flex flex-wrap items-start gap-x-2 pb-1">
                            <DateRangeFilterControls filterValues={filterValues} setFilterValues={setFilterValues} />
                            <IntervalFilterControls filterValues={filterValues} setFilterValues={setFilterValues} />
                        </Card>
                    </div>
                </div>
                <div className="space-y-8" data-testid="consumptionView">
                    {lastIngestionDates &&
                        volumeEntriesByMarketLocations
                            ?.filter(a => a.marketLocation.meteringType !== 'SLP')
                            .map(({ marketLocation, entries }, index) => (
                                <div key={index}>
                                    <LocationHeader marketLocation={marketLocation} />
                                    {entries && (
                                        <StatsCard
                                            data={entries}
                                            interval={filterValues.interval}
                                            lastIngestionDate={
                                                lastIngestionDates.find(
                                                    x => x.volumeCurveId === marketLocation.volumeCurveId,
                                                )!.lastIngestionDate ?? null
                                            }
                                            selectedDates={selectedDates}
                                            coordinates={marketLocation.coordinates}
                                            period={filterValues.period}
                                            timeZone={marketLocation.timeZone}
                                            view="bar"
                                        />
                                    )}
                                </div>
                            ))}
                    {lastIngestionDates && !!slpLocations?.length && (
                        <div>
                            <div className="text-xl md:text-2xl font-bold pb-4">
                                {intl.formatMessage({ id: 'consumptionView.titleSLP' })}
                            </div>
                            {slpLocations?.map(({ marketLocation }, index) => (
                                <div key={index}>
                                    <LocationHeader marketLocation={marketLocation} isSLP />
                                </div>
                            ))}
                        </div>
                    )}
                    {!volumeEntriesByMarketLocations &&
                        new Array(volumeCurveIds.length || 1).fill(null).map((_, i) => <StatsCardSkeleton key={i} />)}
                </div>
            </div>
        </div>
    );
}

const LocationHeader: FC<{ marketLocation: MarketLocation; isSLP?: boolean }> = ({ marketLocation, isSLP }) => {
    const intl = useIntl();
    return (
        <div>
            <div
                className={`font-bold pb-1 ${isSLP ? 'text-lg md:text-xl' : 'text-xl md:text-2xl'}`}
                data-testid="locationHeader"
            >
                {marketLocation.label && (
                    <span>
                        {marketLocation.label}
                        {' | '}
                    </span>
                )}
                <span>
                    {intl.formatMessage({ id: 'consumptionView.id' })}: {marketLocation.id}
                </span>
            </div>
            <div className="pb-4" data-testid="address">
                {getAddress(marketLocation)}
            </div>
        </div>
    );
};

type MarketLocation = inferRouterOutputs<AppRouter>['dashboard']['getMarketLocations'][number];
