type PasswordlessAuthState = {
    tokens?: {
        expireAt?: Date;
        accessToken?: string;
        refreshToken?: string;
    };
    refreshTokens: () => Promise<{ accessToken: string }>;
};

export const refreshBeforeExpiryMinutes = 2;

export async function getAccessTokenFromPasswordlessCognito(
    passwordlessAuthState: PasswordlessAuthState,
): Promise<string | undefined> {
    if (
        passwordlessAuthState.tokens &&
        passwordlessAuthState.tokens.expireAt &&
        passwordlessAuthState.tokens.refreshToken &&
        expiresInLessThan({ date: passwordlessAuthState.tokens.expireAt, minutes: refreshBeforeExpiryMinutes })
    ) {
        // NOTE: normally access tokens are auto-refreshed by the library.
        // See how the auth library implemented it: https://github.com/aws-samples/amazon-cognito-passwordless-auth/blob/e11af2880e4cd8d526b222efd6e53287c06eeac5/client/react/hooks.tsx#L360
        // See when to expire: https://stackoverflow.com/a/70997548
        // This is done manually to try fix an issue with some users access tokens not auto-refreshing.
        const tokens = await passwordlessAuthState.refreshTokens();
        return tokens.accessToken;
    }

    return passwordlessAuthState.tokens?.accessToken;
}

function expiresInLessThan({ date, minutes }: { date: Date; minutes: number }) {
    // let's do that in Temporal when browsers implement it natively
    const now = new Date();
    now.setMinutes(now.getMinutes() + minutes);
    return date < now;
}
