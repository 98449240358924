import { Direction } from './types.ts';
import { directionToGradientMap, useFormatKwh } from './utils.ts';
import { PowerChip } from './PowerChip.tsx';
import { useIntl } from 'react-intl';

export type PowerPillProps = {
    value: number | undefined;
    direction: Direction;
    className: string;
};

export const PowerPill = ({ value, direction, className }: PowerPillProps) => {
    const formatKwh = useFormatKwh();
    const intl = useIntl();

    return (
        <div className={className}>
            {value !== undefined ? (
                <PowerChip color={directionToGradientMap[direction]}>{formatKwh(value)}</PowerChip>
            ) : (
                <PowerChip color={directionToGradientMap['unavailable']} minWidthClass="min-w-max">
                    <span className="inline px-2">{intl.formatMessage({ id: 'overview.noData' })}</span>
                </PowerChip>
            )}
        </div>
    );
};
