import { FC, useState } from 'react';
import { Temporal } from 'temporal-polyfill';
import { Calendar, CalendarIcon, Popover, PopoverContent, PopoverTrigger } from '@trawa-energy/ui-kit';

type ConsumptionGridDatepickerProps = {
    selected: Date;
    onChange: (date: Date) => void;
    label: string;
};
export const ConsumptionGridDatepicker: FC<ConsumptionGridDatepickerProps> = ({ selected, onChange, label }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                <button
                    type="button"
                    aria-pressed={isOpen}
                    className="flex items-center gap-2 border px-4 py-3 rounded-xl hover:text-primary bg-white font-bold"
                >
                    <CalendarIcon className="text-primary" />
                    {label}
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-4 bg-white" align="start">
                <Calendar
                    mode="single"
                    selected={selected}
                    disabled={{
                        from: new Date(Temporal.Now.plainDateISO().toString()),
                        to: new Date(Temporal.Now.plainDateISO().add({ years: 99 }).toString()),
                    }}
                    onSelect={(date: Date | undefined) => {
                        if (date === undefined) {
                            return;
                        }

                        onChange(date);
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};
