import { AnimatePresence, motion } from 'framer-motion';
import { PowerSupplier as PowerSupplierT, Mode } from './types.ts';
import { PowerSupplier } from './PowerSupplier.tsx';
import { useFeatureFlags } from '../../hooks/useFeatureFlags.ts';

export const Suppliers = ({
    mode,
    powerSuppliers,
    endElementId,
    maxMarketLocationPeakKwh,
}: {
    mode: Mode;
    powerSuppliers: PowerSupplierT[];
    endElementId: string;
    maxMarketLocationPeakKwh: number;
}) => {
    const { isManageOnly } = useFeatureFlags();
    return (
        !isManageOnly && (
            <AnimatePresence>
                {mode.type === 'default' && (
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="w-full flex justify-around gap-2 sm:gap-6 2xl:w-2/3"
                    >
                        {powerSuppliers
                            // TODO replace with .toSorted() when supported
                            .sort((x, y) => {
                                const order: PowerSupplierT['type'][] = ['wind', 'solar', 'spot', 'futures'];

                                return order.indexOf(x.type) - order.indexOf(y.type);
                            })
                            .map(({ id, kwh, direction, type }, index) => {
                                return (
                                    <PowerSupplier
                                        key={id}
                                        id={id}
                                        kwh={kwh}
                                        direction={direction}
                                        type={type}
                                        endElementId={endElementId}
                                        numberOfPpas={powerSuppliers.length}
                                        index={index}
                                        maxMarketLocationPeakKwh={maxMarketLocationPeakKwh}
                                    />
                                );
                            })}
                    </motion.div>
                )}
            </AnimatePresence>
        )
    );
};
