const formatPowerInkWh = (kwh: number, locale: string) => ({
    kwh,
    kwhLocalString: kwh.toLocaleString(locale, {
        maximumFractionDigits: 0,
    }),
    unit: 'kWh',
});

const formatPowerInGWh = (kwh: number, locale: string) => ({
    kwh: kwh / 1e6,
    kwhLocalString: (kwh / 1e6).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }),
    unit: 'GWh',
});

export const formatPower = (kwh: number, locale: string, powerUnit?: 'kWh' | 'GWh') => {
    if (powerUnit) {
        return powerUnit === 'kWh' ? formatPowerInkWh(kwh, locale) : formatPowerInGWh(kwh, locale);
    }
    if (kwh < 1e6) {
        return formatPowerInkWh(kwh, locale);
    }
    return formatPowerInGWh(kwh, locale);
};

export const formatEnergy = (kwh: number, locale: string) => {
    const { kwhLocalString, unit } = formatPower(kwh, locale);
    return `${kwhLocalString} ${unit}`;
};

export const getMaxPowerUnit = (consumptions: number[], locale: string) => {
    const maxConsumption = Math.max(...consumptions);
    return formatPower(maxConsumption, locale).unit as 'kWh' | 'GWh';
};
