import React, { FC, ReactNode } from 'react';

import { QuestionMarkIcon } from '@trawa-energy/ui-kit';
import { useIntl } from 'react-intl';

type Position = 'absolute' | 'relative' | 'fixed';

interface IWidgetPosition {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    position?: Position;
}
interface ISupportWidget {
    background?: string;
    text?: string;
    icon?: ReactNode;
    color?: string;
    position?: IWidgetPosition;
}

/**
 * custom trigger for support widget
 * handles desktop and mobile positioning
 */
export const SupportWidget: FC<ISupportWidget> = ({
    background,
    text,
    icon,
    color,
    position = { position: 'absolute', bottom: '1rem', left: '1rem' },
}) => {
    const openSupportWidget = () => {
        window.FreshworksWidget('open');
    };

    const intl = useIntl();

    return (
        <div
            style={{ backgroundColor: background, color, ...position }}
            data-testid="support-widget"
            className="flex
            w-[fit-content]
            cursor-pointer
            align-middle
            font-medium
            text-sm
            px-2
            py-2
            pr-3.5
            bg-primary-green
            text-black
            rounded-3xl
            rounded-tr-md
            transition-all
            duration-1000
            hover:scale-105"
            onClick={() => openSupportWidget()}
        >
            {icon || <QuestionMarkIcon width="1.2rem" height="1.2rem" />}
            <div className="ml-1">
                {text || <span>{intl.formatMessage({ id: 'sidebar.supportWidget.trigger' })}</span>}
            </div>
        </div>
    );
};
