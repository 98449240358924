import React, { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { LoginContainer } from './login/LoginContainer';
import { config } from '../config';

const MaintenancePage: React.FC = () => {
    const intl = useIntl();

    return (
        <LoginContainer>
            <h1 className="text-2xl md:text-3xl font-bold pb-8 pt-16 md:pt-0 max-w-sm">
                {intl.formatMessage({ id: 'maintenancePage.title' })}
            </h1>
            <h2 className="text-1xl md:text-2xl font-bold pb-5 pt-5 md:pt-0 max-w-sm">
                {intl.formatMessage({ id: 'maintenancePage.subTitle' })}
            </h2>
            <div>
                {intl.formatMessage(
                    { id: 'maintenancePage.body' },
                    {
                        endTime: config.maintenanceEndTime,
                    },
                )}
            </div>
        </LoginContainer>
    );
};

export default MaintenancePage;
