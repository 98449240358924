import { useIntl } from 'react-intl';
import { EChart } from '../EChart.tsx';
import { EChartsOption, MarkAreaComponentOption, YAXisComponentOption } from 'echarts';
import { PricesType, PriceItem } from '../../types/prices';
import { useMemo } from 'react';

export function ExchangePriceChart({
    isLoading,
    prices,
    height,
    markLineData,
    markArea,
    yAxis,
    xAxis = {},
    className = '',
    tooltip,
}: {
    isLoading: boolean;
    prices?: PricesType['data'];
    height?: string;
    yAxis: YAXisComponentOption;
    markLineData?: { xAxis: number; label: { formatter?: string } }[];
    markArea?: MarkAreaComponentOption;
    xAxis?: EChartsOption['xAxis'];
    className?: string;
    tooltip?: EChartsOption['tooltip'];
}) {
    const intl = useIntl();
    const xAxisData = useMemo(() => {
        if (prices === undefined) {
            return [];
        }

        return [
            ...prices[0].map((item: PriceItem) => item.actualPrice.toFixed(2)),
            ...prices[1].map((item: PriceItem) => item.actualPrice.toFixed(2)),
        ];
    }, [prices]);

    return (
        <EChart
            className={className}
            height={height}
            isLoading={isLoading}
            option={{
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis,
                yAxis,
                tooltip,
                visualMap: [
                    {
                        show: false,
                        type: 'continuous',
                        seriesIndex: 0,
                        min: 0,
                        max: 30,
                        inRange: {
                            color: ['#27ae60', '#f1c40f', '#e74c3c'],
                        },
                    },
                ],
                series: [
                    {
                        name: intl.formatMessage({ id: 'exchangePrices.price' }),
                        type: 'line',
                        step: 'end',
                        data: xAxisData,
                        symbol: 'none',
                        markLine: {
                            symbol: 'none',
                            lineStyle: {
                                type: 8,
                                color: '#404040',
                            },
                            data: markLineData,
                        },
                        markArea: markArea,
                    },
                ],
            }}
        />
    );
}
