import z from 'zod';

const booleanStringSchema = z.union([z.literal('true'), z.literal('false')]);

export type PortalEnv = z.input<typeof envSchema>;

const envSchema = z.object({
    VITE_PORTAL_API_BASE_URL: z.string().url(),
    VITE_PORTAL_GOOGLE_MAPS_API_KEY: z.string(),
    VITE_AWS_COGNITO_REGION: z.string(),
    VITE_AWS_USER_POOLS_ID: z.string(),
    VITE_AWS_USER_POOLS_WEB_CLIENT_ID: z.string(),
    VITE_DO_NOT_BATCH_REQUESTS: booleanStringSchema.optional(), // enable when testing on API mocks
    VITE_SENTRY_DSN_TOKEN: z.string().optional(),
    VITE_SENTRY_ENABLED: booleanStringSchema.optional(),
    VITE_SENTRY_ENVIRONMENT: z.string().optional(),
    VITE_FEATUREVISOR_URL: z.string().optional(),
    VITE_PASSWORDLESS_USER_POOL_ID: z.string(),
    VITE_PASSWORDLESS_USER_POOL_CLIENT_ID: z.string(),
    VITE_PASSWORDLESS_COGNITO_IDP_ENDPOINT: z.string(),
    VITE_PORTAL_UNDER_MAINTENANCE: booleanStringSchema.optional(),
    VITE_PORTAL_SHOW_MAINTENANCE_BANNER: z.string().optional(),
    VITE_PORTAL_MAINTENANCE_DAY_AND_MONTH: z.string().optional(),
    VITE_PORTAL_MAINTENANCE_START_TIME: z.string().optional(),
    VITE_PORTAL_MAINTENANCE_END_TIME: z.string().optional(),
});
const parsedEnv = envSchema.parse(import.meta.env);

export const config = {
    portalApiBaseUrl: parsedEnv.VITE_PORTAL_API_BASE_URL,
    googleMapsApiKey: parsedEnv.VITE_PORTAL_GOOGLE_MAPS_API_KEY,
    doNotBatchRequests: parsedEnv.VITE_DO_NOT_BATCH_REQUESTS == 'true',
    sentryDsnToken: parsedEnv.VITE_SENTRY_DSN_TOKEN,
    sentryEnabled: parsedEnv.VITE_SENTRY_ENABLED == 'true',
    sentryEnvironment: parsedEnv.VITE_SENTRY_ENVIRONMENT ?? 'development',
    amplifyAuth: {
        aws_cognito_region: parsedEnv.VITE_AWS_COGNITO_REGION,
        aws_user_pools_id: parsedEnv.VITE_AWS_USER_POOLS_ID,
        aws_user_pools_web_client_id: parsedEnv.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
        aws_mandatory_sign_in: 'enable',
    },
    featurevisorUrl: parsedEnv.VITE_FEATUREVISOR_URL,
    passwordless: {
        clientId: parsedEnv.VITE_PASSWORDLESS_USER_POOL_CLIENT_ID,
        cognitoIdpEndpoint: parsedEnv.VITE_PASSWORDLESS_COGNITO_IDP_ENDPOINT,
        userPoolId: parsedEnv.VITE_PASSWORDLESS_USER_POOL_ID,
    },
    isUnderMaintenance: parsedEnv.VITE_PORTAL_UNDER_MAINTENANCE == 'true',
    showMaintenanceBanner: parsedEnv.VITE_PORTAL_SHOW_MAINTENANCE_BANNER == 'true',
    maintenanceDayAndMonth: parsedEnv.VITE_PORTAL_MAINTENANCE_DAY_AND_MONTH,
    maintenanceStartTime: parsedEnv.VITE_PORTAL_MAINTENANCE_START_TIME,
    maintenanceEndTime: parsedEnv.VITE_PORTAL_MAINTENANCE_END_TIME,
};
