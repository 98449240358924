import { useIntl } from 'react-intl';
import { EChart } from '../EChart.tsx';
import { EChartsOption, YAXisComponentOption } from 'echarts';
import { formatPower, getMaxPowerUnit } from '../../utils/formatting/formatPowerConsumption.ts';
import { Temporal } from 'temporal-polyfill';

type ConsumptionChartProps = {
    isLoading: boolean;
    height?: string;
    yAxis?: YAXisComponentOption;
    xAxis?: EChartsOption['xAxis'];
    className?: string;
    isTotalConsumptionDataReady: boolean;
    consumptions?: number[];
    yearMonths?: Temporal.PlainYearMonth[];
    today: Temporal.PlainDate;
};

export function ConsumptionChart({
    isLoading,
    height,
    className = '',
    isTotalConsumptionDataReady,
    consumptions = [],
    yearMonths = [],
    today,
}: ConsumptionChartProps) {
    const intl = useIntl();
    const maxConsumptionUnit = getMaxPowerUnit(consumptions, intl.locale);

    // when there are at least 3 months consumption data, display the last 3 - 6 months
    // when there are less than 3 months consumption data, display the data and fill the rest with 0
    const calDisplayMonths = () => {
        const currentYearMonth = today.toPlainYearMonth();
        if (yearMonths.length < 2) {
            return [currentYearMonth, currentYearMonth.add({ months: 1 }), currentYearMonth.add({ months: 2 })];
        }

        if (yearMonths.length === 2) {
            return [currentYearMonth.subtract({ months: 1 }), currentYearMonth, currentYearMonth.add({ months: 1 })];
        }
        return yearMonths;
    };

    const calDisplayConsumptions = (consumptions: number[]) => {
        return consumptions.length >= 3
            ? consumptions.slice(-6)
            : consumptions.concat(new Array(3 - consumptions.length).fill(0));
    };

    return (
        <EChart
            className={className}
            height={height}
            isLoading={isLoading}
            option={{
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    data: calDisplayMonths().map(yearMonth =>
                        intl.formatDate(yearMonth.toPlainDate({ day: 1 }).toString(), {
                            month: 'short',
                        }),
                    ),
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 10,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: maxConsumptionUnit,
                    nameGap: 20,
                    nameTextStyle: {
                        fontSize: 10,
                        fontWeight: 'bold',
                        color: 'black',
                    },
                    axisLabel: {
                        fontSize: 10,
                        formatter: val => formatPower(val, intl.locale, maxConsumptionUnit).kwhLocalString,
                    },
                },
                series: [
                    {
                        name: intl.formatMessage({ id: 'exchangePrices.price' }),
                        type: 'bar',
                        data: calDisplayConsumptions(consumptions),
                        barWidth: '35%',
                        barMaxWidth: '26px',
                        barMinWidth: '17px',
                        itemStyle: {
                            color: '#0558c7ff',
                        },
                    },
                ],
            }}
        />
    );
}
