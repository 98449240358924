import { AccountType, useUserSettings } from '../userSettings';
import { trpc } from '../utils/trpc';
import { useGetMarketLocations } from './useMarketLocations';
import { Temporal } from 'temporal-polyfill';
import { getPlainDateTimeFromString } from '../utils/getPlainDateTimeFromString';

const dateRangeLastNMonths = (n: number, today: Temporal.PlainDate) => {
    const startDate = today.subtract({ months: n - 1 });
    return {
        from: Temporal.PlainDate.from({ year: startDate.year, month: startDate.month, day: 1 }).toString(),
        exclusiveTo: today.add({ days: 1 }).toString(),
    };
};

export const useVolumeEntries = (today: Temporal.PlainDate) => {
    const { userSettings } = useUserSettings();
    const contractPartnerId =
        userSettings.accountType === AccountType.RealEstate ? userSettings.contractPartnerId : undefined;
    const { data: marketLocations } = useGetMarketLocations();
    const { data: volumeEntries } = trpc.dashboard.getVolumeEntries.useQuery(
        {
            dateRange: dateRangeLastNMonths(6, today),
            contractPartnerId,
            aggregationInterval: 'month',
        },
        { enabled: !!marketLocations, staleTime: 1000 * 60 * 5 },
    );

    if (!volumeEntries) {
        return { yearMonths: [], consumptions: [] };
    }

    const volumeEntriesGroupedByMonth = Object.groupBy(volumeEntries, volumeEntry =>
        getPlainDateTimeFromString(volumeEntry.timestampStart).toPlainYearMonth().toString(),
    );

    const yearMonths = Object.keys(volumeEntriesGroupedByMonth);
    const consumptions = yearMonths.map(yearMonth =>
        volumeEntriesGroupedByMonth[yearMonth]!.reduce((acc, currentValue) => acc + currentValue.kwh, 0),
    );

    return { yearMonths: yearMonths.map(yearMonth => Temporal.PlainYearMonth.from(yearMonth)), consumptions };
};
