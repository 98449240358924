import { useEffect, useMemo, useState } from 'react';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { trpc } from '../utils/trpc';
import { CompanyConsumption } from '../components/consumption-grid/types.ts';
import {
    MarketLocationWithVolumeEntriesWithKwh,
    UseGridDataParams,
    composeData,
    getMarketLocationsForAddress,
} from './useGridData.ts';
import { getPlainDateTimeFromString } from '../utils/getPlainDateTimeFromString.ts';
import { TimeZones } from '../locales/utils.ts';

type MarketLocation = inferRouterOutputs<AppRouter>['tvConsumption']['getMarketLocations'][number];

export const useTVGridData = ({ localTimestamp: localTimestampStart }: UseGridDataParams) => {
    const contractPartnerId = undefined;

    const [startDate, setStartDate] = useState(localTimestampStart);

    useEffect(() => {
        if (
            !startDate
                .toZonedDateTime('Europe/Berlin')
                .toPlainDate()
                .equals(localTimestampStart.toZonedDateTime('Europe/Berlin').toPlainDate())
        ) {
            setStartDate(localTimestampStart);
        }
    }, [startDate, localTimestampStart]);

    const {
        data: account,
        isLoading: isLoadingAccount,
        isError: isErrorAccount,
    } = trpc.tvConsumption.getCurrent.useQuery({});

    const dateRange = useMemo(
        () => ({
            // add one day each cause API treads the end date as exclusive
            from: startDate.toString(),
            exclusiveTo: startDate.add({ days: 1 }).toString(),
        }),
        [startDate],
    );

    const {
        data: marketLocations = [],
        isLoading: isLoadingMarketLocations,
        isError: isErrorMarketLocations,
    } = trpc.tvConsumption.getMarketLocations.useQuery(
        {
            contractPartnerId,
            dateRange,
        },
        {},
    );

    const {
        data: products = [],
        isLoading: isLoadingProducts,
        isError: isErrorProducts,
    } = trpc.tvConsumption.getContractPartnerSupplyProducts.useQuery({ contractPartnerId }, {});

    const {
        data: supply = [],
        isLoading: isLoadingSupply,
        isError: isErrorSupply,
    } = trpc.tvConsumption.getSupply.useQuery(
        {
            contractPartnerId,
            dateRange,
        },
        {},
    );

    const maxMarketLocationPeakKwh = Math.max(
        ...marketLocations.map(({ marketLocationPeak }) => marketLocationPeak.kwh),
    );

    // TODO: Unify this function with the one in useGridData.ts
    const addresses: CompanyConsumption['addresses'] = marketLocations
        .map(({ addressCity }) => addressCity)
        .filter((addressCity): addressCity is string => addressCity !== null)
        .filter((addressCity, index, self) => self.indexOf(addressCity) === index) // filter duplicates
        .map(addressCity => {
            const marketLocationsForAddress = getMarketLocationsForAddress(
                marketLocations,
                addressCity,
                (marketLocation: MarketLocation) => {
                    return {
                        ...marketLocation,
                        kwh: marketLocation.volumeEntriesByMarketLocation?.find(volumeEntry => {
                            const volumeEntryTemporal = getPlainDateTimeFromString(
                                volumeEntry.timestampStart,
                                marketLocation.timeZone,
                            ).toString();

                            return volumeEntryTemporal === localTimestampStart.toString();
                        })?.kwh,
                        pvDatakwh: marketLocation.pvData
                            ? marketLocation.pvData.find(pvData => {
                                  const volumeEntryTemporal = getPlainDateTimeFromString(
                                      pvData.timestampStart,
                                      marketLocation.timeZone,
                                  ).toString();

                                  return volumeEntryTemporal === localTimestampStart.toString();
                              })?.kwh
                            : '',
                    } as MarketLocationWithVolumeEntriesWithKwh;
                },
            );

            const kwhForAddress = marketLocationsForAddress.reduce((totalKwh, marketLocation) => {
                return totalKwh + (marketLocation.kwh ?? 0);
            }, 0);

            return {
                id: addressCity,
                name: addressCity,
                kwh: kwhForAddress,
                marketLocations: marketLocationsForAddress,
            };
        })
        .filter(address => address.marketLocations.length > 0);

    const timestampStartUtc = localTimestampStart?.toZonedDateTime(TimeZones.EUROPE_BERLIN).epochMilliseconds;
    const supplyForTimestamp = timestampStartUtc
        ? supply.filter(x => new Date(x.timestampStart).getTime() === timestampStartUtc)
        : [];

    const composedData =
        account && composeData(account, products, addresses, supplyForTimestamp, maxMarketLocationPeakKwh);

    if (composedData) {
        composedData.addresses =
            composedData?.addresses.map(address => {
                const marketLocationsWithOutSLP = address.marketLocations.filter(
                    marketLocation => marketLocation.type !== 'SLP',
                );
                return { ...address, marketLocations: marketLocationsWithOutSLP };
            }) || [];
    }

    return {
        data: composedData,
        isLoading: isLoadingAccount || isLoadingMarketLocations || isLoadingProducts || isLoadingSupply,
        isError: isErrorAccount || isErrorMarketLocations || isErrorProducts || isErrorSupply,
    };
};
