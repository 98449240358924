import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { Card } from '../components/Card.tsx';
import { NestedTable } from '../components/NestedTable.tsx';
import { cn } from '@trawa-energy/ui-kit';
import { PageHeading } from '../components/PageHeading.tsx';
import { Temporal } from 'temporal-polyfill';
import { getTodaysDate, getTomorrowsDate } from '../utils/getNthDateFromToday.ts';
import { useMemo } from 'react';
import { ExchangePriceChart } from '../components/charts/ExchangePriceChart.tsx';
import { EChartsOption, YAXisComponentOption, MarkAreaComponentOption } from 'echarts';
import { formatPriceString } from '../utils/formatting/formatPriceString.ts';

export function ExchangePrices() {
    const intl = useIntl();
    const { data: prices, isLoading } = trpc.prices.getAheadPrices.useQuery({});

    const isPast = (date: string) => {
        const parsedDate = Temporal.Instant.from(date);
        const now = Temporal.Now.instant();

        return parsedDate.epochMilliseconds < now.epochMilliseconds;
    };

    const formatTableRows = () => {
        const today = prices?.data[0];
        const tomorrow = prices?.data[1];

        if (!today) return [];

        const rows = [];

        for (let i = 0; i < today?.length; i++) {
            // hour
            const cell1 = `${intl.formatDate(today[i].timestampStart, { timeStyle: 'short' })} - ${intl.formatDate(today[i].timestampEnd, { timeStyle: 'short' })}`;
            // today's value in ct / kWh
            const cell2 = (
                <span className={cn(isPast(today[i].timestampEnd) && 'text-gray-4')}>
                    {formatPriceString(today[i].actualPrice, today[i].priceUnit, intl.locale, intl.formatMessage)}
                </span>
            );
            // tomorrow's value in ct / kWh
            const cell3 = tomorrow?.[i]
                ? formatPriceString(tomorrow[i].actualPrice, tomorrow[i].priceUnit, intl.locale, intl.formatMessage)
                : '-';

            rows.push({ cells: [cell1, cell2, cell3] });
        }

        return rows;
    };

    const formatTableHeadings = () => {
        const headings = ['hour', 'today', 'tomorrow'].map((h, i) => {
            return intl.formatMessage(
                { id: `exchangePrices.${h}` },
                { date: i < 2 ? getTodaysDate() : getTomorrowsDate() },
            );
        });

        return headings;
    };

    const tableData = {
        headings: formatTableHeadings(),
        body: !isLoading ? formatTableRows() : [],
    };

    const xAxisData = useMemo(() => {
        if (prices === undefined) {
            return [];
        }

        return [
            ...prices.data[0].map(item => `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`),
            ...prices.data[1].map(item => `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`),
        ];
    }, [prices, intl]);

    const priceUnit = prices?.data[0][0].priceUnit ?? '';

    const xAxis = {
        type: 'category',
        data: xAxisData,
        axisTick: {
            alignWithLabel: true,
        },
    } as EChartsOption['xAxis'];

    const yAxis: YAXisComponentOption = {
        type: 'value',
        name: intl.formatMessage({ id: 'exchangePrices.yAxisLabel' }),
        nameGap: 30,
        nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            color: 'black',
        },
    };

    const tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'line',
        },
        valueFormatter: (value?: number) => {
            if (value === undefined) {
                return '';
            }
            return formatPriceString(value as number, priceUnit, intl.locale, intl.formatMessage);
        },
    } as EChartsOption['tooltip'];

    const markLineData = [
        {
            xAxis: Temporal.Now.plainTimeISO().hour,
            label: {
                formatter: intl.formatMessage({ id: 'exchangePrices.now' }),
            },
        },
        {
            xAxis: prices?.data[0].length ?? 0,
            label: {
                formatter: getTomorrowsDate(intl.locale),
            },
        },
    ];
    const markArea: MarkAreaComponentOption = {
        itemStyle: {
            color: '#F3F3F3',
            opacity: 0.5,
        },
        emphasis: {
            itemStyle: {
                color: '#F3F3F3',
            },
        },
        data: [
            [
                {
                    xAxis: 0,
                },
                {
                    xAxis: Temporal.Now.plainTimeISO().hour,
                },
            ],
        ],
    };

    return (
        <section>
            <PageHeading title={'exchangePrices.title'} />
            <Card className="mb-6">
                <ExchangePriceChart
                    prices={prices?.data}
                    isLoading={isLoading}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    tooltip={tooltip}
                    markLineData={markLineData}
                    markArea={markArea}
                />
            </Card>
            <Card>
                <NestedTable headings={tableData.headings} rows={tableData.body} isLoading={isLoading} />
            </Card>
        </section>
    );
}
